import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./css/login.css";
import Logo  from "../../assets/logo Prodbleu.png";
import { Auth } from 'aws-amplify';
import Cookies from "js-cookie";
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import MessageSupport from "../../client/composant/MessageSupport";

const initialState = {
    email: "", 
    password: ""
};

function Login() {
    const navigate = useNavigate();
    const [formData, setFormdata] = useState(initialState);
    const [isLoggingIn, setIsLoggingIn] = useState(false); 
    const [showPassword, setShowPassword] = useState(false)


    const handleChange = (e) => {
        setFormdata({
            ...formData, 
            [e.target.name]: e.target.value,
        });
    };

    const tooltipStyles = {
        fontSize: "14px", 
    };


    const handleCo = async (e) => {
        e.preventDefault();

        if (isLoggingIn) {
            return; // Quittez la fonction si une tentative de connexion est déjà en cours
        }

        setIsLoggingIn(true); // Indiquer qu'une tentative de connexion est en cours
        try {
            const cognitoUser = await Auth.signIn(formData.email, formData.password);
    
            // Récupérez la session de l'utilisateur
            const session = await Auth.currentSession();
            const groups = session.getIdToken().payload["cognito:groups"] || [];
            
            const email = cognitoUser.attributes.email;
            Cookies.set("email", email);
    
            if (groups && groups.length) {
                switch (groups[0]) { // Supposons que l'utilisateur ne peut appartenir qu'à un seul groupe à la fois
                    case "ADMIN":
                        navigate("/choice");
                        break;
                    case "GESTIONNAIRE":
                        navigate("/choice");
                        break;
                    case "COMMERCIAL":
                        navigate("/status");
                        break;
                    default:
       toast.error("erreur")         
       }
            } else {

                toast.error("erreur")
            }
    
            toast.success('Connecté avec succès!');
    
        } catch (error) {
            console.error(`Erreur de connexion : ${error}`);
            toast.error(`Erreur : ${error.message}`);
        } finally {
            setIsLoggingIn(false); 
        }
    };

    const handleForgot = () => {
        navigate("/forgotPassword");
    };
    
    const handleValidate = () => {
        navigate("/confirmSignup");
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="container">
            <div className="container-login">
                <div className="wrap-login">
                    <div className="login-header">
                        <img src={Logo} alt="logo"  className= "login-image"/>
                        <h2 className="login-form-title"> Connexion </h2>
                    </div>
                    <form onSubmit={handleCo} className="login-form">
                        <label className="form-label">Adresse email</label>
                        <input
                            value={formData.email}
                            type="text"
                            className="login-input"
                            placeholder="xxxx@xxx.com"
                            onChange={handleChange}
                            name="email"
                            required
                        />
                        
                        <label className="form-label">Mot de passe</label>
                        <div className="form-password">
                        <input
                            value={formData.password}
                            type={showPassword ? "text" : "password"}
                            className="form-password-input"
                            placeholder="**********"
                            name="password"
                            required
                            onChange={handleChange}
                        />
                        <IconButton
                        onClick={ togglePasswordVisibility}
                        aria-label="toggle confirm password visibility"
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    </div>
                        <button type="submit" className="login-form-btn" disabled={isLoggingIn} style={{ position: 'relative' }}>
                        {isLoggingIn ? (
                            <>
                                
                                <CircularProgress
                                    size={24} 
                                    style={{
                                        color: 'white', 
                                        position: 'absolute', 
                                        top: '50%', 
                                        left: '22%', 
                                        marginTop: '-12px', 
                                        marginLeft: '-12px', 
                                    }}
                                />
                                Connexion en cours...
                            </>
                        ) : 'Se connecter'}
                    </button>
                    </form>
                    <div className="text-footer">
                        <span className="btn-forgot">
                            <i onClick={handleForgot}>Mot de passe oublié</i>
                        </span>
                        <span className="btn-verify">
                            <i onClick={handleValidate}>1ère inscription</i>
                        </span>
                    </div>
                </div>
                <div>
                 <MessageSupport/>
                </div>
            </div>
        </div>
    );
}

export default Login;
