import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./css/headerpage.css";

function HeaderPage() {
 const navigate = useNavigate()

 const handleClick = () => {
  navigate("/")
 }
  return (
    <div className='headerPage'>
      <h2 className='header-title'>Renseignez les champs suivants pour obtenir un devis
           <button onClick={handleClick} className='btn-deco'> Se déconnecter</button></h2>
    </div>
  )
}

export default HeaderPage