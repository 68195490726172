import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Admin/Pages/Login";
import BranchPage from "./Admin/Pages/BranchPage";
import ForgotPassword from "./Admin/Pages/ForgotPassword";
import FormUser from "./Admin/Pages/FormUser";
import FormUserUpdate from "./Admin/Pages/FormUserUpdate";
import FromStatus from "./client/FromStatus";
import FormUpdate from "./client/FormUpdate";
import FormClient from "./client/FormClient";
import PdfPage from "./client/PdfPage";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BranchProvider } from "./Context/Branche";
import Choice from "./Admin/Pages/Choice";
import AccountPage from "./Admin/Pages/AccountPage";
import NewBranch from "./Admin/Pages/NewBranch";
import UpdateBranche from "./Admin/Pages/UpdateBranche";
import YourProfile from "./Admin/Pages/YourProfile";
import axios from "axios"
import ResetPasswordUsingVerificationCode from "./Admin/Pages/ResetPasswordUsingVerificationCode";
import ConfirmSignUp from "./Admin/Pages/ConfirmSignup";
import HelpPage from "./client/composant/HelpPage";

axios.defaults.withCredentials= true

function App() {
  return (
    <>
    <BranchProvider>
      <BrowserRouter>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/confirmSignup" element={<ConfirmSignUp />} />
            <Route path="/resetUsingVerificationCode" element={<ResetPasswordUsingVerificationCode />} />
            <Route path="/choice" element={<Choice />} />

            <Route path="/status" element={<FromStatus />} />
            <Route path="/updatesatus/:id" element={<FormUpdate />} />
            <Route path="/step3" element={<FormClient />} />
            <Route path="/step4" element={<PdfPage />} />

            <Route path="/AccountUser" element={<AccountPage />} />
            <Route path="/branch" element={<BranchPage />} />
            <Route path="/newbranch" element={<NewBranch />} />
            <Route path="/formUser" element={<FormUser />} />
            <Route path="/formUserUpdate" element={<FormUserUpdate />} />
            <Route path="/updateBranche/:id" element={<UpdateBranche/>}/>
            <Route path="/profile" element={<YourProfile/>} />

            <Route path="/help" element={<HelpPage/>} />
          </Routes>
      </BrowserRouter>
      </BranchProvider>
    </>
  );
}

export default App;
