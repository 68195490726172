import React from "react";
import { Container} from "@mui/material";
import Navbar from "../components/Navbar";
import "./css/BranchPage.css";
import TableauBranche from "../components/TableBranch";


function BranchPage() {
  return (
    <div className="branchepage">
      <Navbar />
      <div className="branchepage-container">
        <div className="branchepage-table">
        <Container className="branchPage-container">
            <TableauBranche />
          </Container>
        </div>
      </div>
    </div>
  );
}

export default BranchPage;
