import React, { useContext, useEffect, useState , useCallback} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Modal, Fade } from "@mui/material";
import { BranchContext } from "../../Context/Branche";
import Navbar from "../components/Navbar";
import "./css/updateBranche.css";

function UpdateBranche() {
  const { getBranch, updateBranch} = useContext(BranchContext);
  const [branch, setBranch] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const branchData = await getBranch(id);
        setBranch(branchData);
        console.log("mes donnée" , JSON.stringify(branchData, null, 2))
      } catch (error) {
        console.error("Erreur lors de la récupération des données de la branche: ", error);
      }
    };

    fetchData().catch(e => console.error(e));
  }, [getBranch, id]);

  const handleChange = (e, targetLevel, formuleId, champId, field) => {
    const newBranch = { ...branch };
  
    if (targetLevel === 'branche') {
        newBranch[field] = e.target.value;
    } else if (targetLevel === 'formule') {
        const formules = newBranch.formules?.items || [];
        newBranch.formules.items = formules.map(f => {
          if (f.id === formuleId) {
            return { ...f, [field]: e.target.value };
          }
          return f;
        });
    } else if (targetLevel === 'champ') {
        const formules = newBranch.formules?.items || [];
        newBranch.formules.items = formules.map(f => {
          if (f.id !== formuleId) return f;
  
          const options = f.options?.items || [];
          const newOptions = options.map(option => {
            const regimes = option.regimes?.items || [];
            const newRegimes = regimes.map(regime => {
              const champs = regime.champs?.items || [];
              const newChamps = champs.map(c => {
                if (c.id === champId) {
                  return { ...c, [field]: e.target.value };
                }
                return c;
              });
              return { ...regime, champs: { items: newChamps } };
            });
            return { ...option, regimes: { items: newRegimes } };
          });
  
          return { ...f, options: { items: newOptions }};
        });
    }
    console.log(JSON.stringify(branch, null, 2))
    setBranch(newBranch);
  };
  
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true); // Afficher le modal
    try {
        await updateBranch(id, branch);
        setTimeout(() => {
            setLoading(false); // Cacher le modal après 1min 5s
            navigate("/branch");
        }, 65000); // 65 secondes
    } catch (error) {
        setLoading(false); // Cacher le modal si une erreur se produit
        console.error("Erreur lors de la mise à jour de la branche: ", error);
    }
}, [branch, id, navigate, updateBranch]);

  if (!branch) {
    return <div>Chargement...</div>;
  }

  return (
    <>
      <Navbar/>
      <div className="updateBranche">
        <h2>Mettre à jour une Branche:</h2>
        <div className="update-container">
          <form className="update-form" onSubmit={handleSubmit}>
            <h2 className="title-field">Nom de la branche:</h2>
            <input
              type="text"
              value={branch.nomBranche}
              onChange={(e) => handleChange(e, 'branche', null, null, 'nomBranche')}
            />
            {branch.formules?.items?.map(formule => (
              <div key={formule.id}>
                <h2 className="title-field">Formule:</h2>
                <label className="label-field">Nom de la formule:</label>
                <input
                  type="text"
                  value={formule.nomFormule}
                  onChange={(e) => handleChange(e, 'formule', formule.id, null, 'nomFormule')}
                />
                {formule.options?.items?.map(option => (
                  <div key={option.id}>
                     <h3 className="title-field">Option: {option.nomOption}</h3> 
                     {option.regimes?.items?.map(regime => (
                      <div key={regime.id}>
                        <h4>Régime: {regime.nomRegime}</h4>
                        {regime.champs?.items?.map(champ => (
                          <div key={champ.id} className="input-field">
                            <label className="label-field">Nom:</label>
                            <input
                              type="text"
                              value={champ.nom}
                              onChange={(e) => handleChange(e, 'champ', formule.id, champ.id, 'nom')}
                            />
                            <label className="label-field">Valeur:</label>
                            <input
                              type="text"
                              value={champ.valeur}
                              onChange={(e) => handleChange(e, 'champ', formule.id, champ.id, 'valeur')}
                            />
                            <span>{champ.typeValeur === "POURCENTAGE" ? "%" : "€"}</span>
                          </div>
                        ))} 
                      </div>
                    ))} 
                  </div>
                ))} 
              </div>
            ))}
            <button type="submit">Enregistrer</button>
          </form>
        </div>
        <Modal open={loading} closeAfterTransition>
                    <Fade in={loading}>
                        <div className="updatebranch-loading-modal">
                            <div className="updatebranch-loading">
                                <CircularProgress style={{ color: "whitesmoke" }} />
                                <h2 className="updatebranch-title-loading">mise a jour de la branche en cours...</h2>
                            </div>
                        </div>
                    </Fade>
                </Modal>
      </div>
    </>
  );

}

export default UpdateBranche;