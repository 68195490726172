import React , {useState, useEffect} from 'react';
import "./css/template2.css"
import Cookies from 'js-cookie';

function Template2() {
   const [resultat, setResultat] = useState("")
   const [percentage, setPercentage] = useState("")
   const [nomBranches, setNomBranches] = useState("")

   useEffect(() => {
    const resultatData = Cookies.get('resultat')
    const percentageData = Cookies.get('percentage')
    const nomBranche = Cookies.get("branche")
    setPercentage(percentageData)
    setResultat(resultatData)
    setNomBranches(nomBranche)

   }, []);

  const user = JSON.parse(localStorage.getItem('user'))
  const resultats =Cookies.get("resultat") || {};
  const percentages = Cookies.get("percentage") || {};
  const numConjoints = Cookies.get('numConjoints');
 

  const options = {day: "numeric", month: "numeric", year: "numeric"}
  
  const userData = Cookies.get("user");
  const parsedUser = userData ? JSON.parse(userData) : {};
  const conjoint = numConjoints === '1' ? 'oui' : 'non';

  const getFormuleValue = () => {
    const selectedChampsCookie = Cookies.get("selectedChamps");
    const selectedChamps = selectedChampsCookie ? JSON.parse(selectedChampsCookie) : [];

    const isOnlySalarie = selectedChamps.length === 1 && selectedChamps.some(champ => 
        champ?.label?.toLowerCase().includes("salarié") || champ?.nom?.toLowerCase().includes("salarié"));

    const hasConjoint = selectedChamps.some(champ => champ.label && champ.label.includes("Conjoint"));

    const branchCookie = Cookies.get("branche");
    const updateFormule = Cookies.get("updateFormule");
    const formule = Cookies.get("setFormule");

    console.log("updateFormule", updateFormule);
    
    if (branchCookie && branchCookie.includes("Architecte")) {
        if (updateFormule) {
            // Vérifiez si 'updateFormule' est une chaîne vide avant d'accéder à sa longueur
            return updateFormule.length === 0 ? formule : updateFormule;
        } else if (isOnlySalarie) {
            return Cookies.get("setFormule");
        } else {
            return formule;
        }
    } else {
        return formule;
    }
}

  

const [data, setData] = useState({
  muna: parsedUser.muna || false,
  datecreation: parsedUser.datecreation,
  branche: Cookies.get('branche') || '',
  regime: Cookies.get("setRegime") || '',
  optiobli: Cookies.get("setOption") || '',
  formule: getFormuleValue() || '',
  nbenfants: '',
  nbascandants: '',
  conjoint: '',
  newRegime: Cookies.get("setRegime") || '',
  newOptiobli: Cookies.get("setOptionUpdate") || '',
  newFormule: getFormuleValue() || '',
  newNbenfants: '',
  newNbascandants: '',
  newConjoint: '-',
  resultat: resultats || '0',
  percentage: percentages || '0',
  userDate: '',
  nom: parsedUser.nom,
  prenom: parsedUser.prenom,
  adress: parsedUser.adress,
  cp: parsedUser.cp,
  ville: parsedUser.ville,
  civilite: parsedUser.civilite,
  comprenom: '',
  comnom: ''
});

const formatDateFR = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('fr-FR');
};

useEffect(() => {
  const email = Cookies.get('email');
  const numEnfant  = Cookies.get("numEnfants")
  const numAscendants = Cookies.get("numAscendants")
  const numEnfantsUpdate = Cookies.get("updatenumEnfants") || numEnfant;
  const numAscendingUpdate = Cookies.get("updatenumAscendants") || numAscendants;
  const isConjointBox = Cookies.get('isConjointbox'); 
  const conjointStatus = isConjointBox === 'true' ? 'oui' : 'non';
  const numConjointsUpdate = Cookies.get("numConjointsUpdate");
  const branched = Cookies.get('branche');

  if (email) {
    const [fullName] = email.split('@');
    const nameParts = fullName ? fullName.split('.') : [];

    let firstName = "";
    let lastName = "";

    if (nameParts.length === 2) {
      firstName = nameParts[0];
      lastName = nameParts[1];
    } else if (nameParts.length === 1) {
      firstName = nameParts[0];
      lastName = ""; 
    } else {
      console.warn("Le format de l'email ne correspond pas au format attendu prénom.nom@domaine.com");
    }

    const capitalize = (str) => str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    let conjointValueUpdated = " ";

    if (branched === "Habitat Social" && data?.formule.includes("Famille")) {
      conjointValueUpdated = '-';
    } else {
      conjointValueUpdated = numConjointsUpdate || conjointStatus
    }

    if(branched.includes("Bureaux") && data?.formule.includes("Famille")) {
      conjointValueUpdated =  conjointStatus
    }



    setData(prevData => {
      if(prevData.formule.includes("Famille")){
         return {
          ...prevData,
          conjoint: numConjointsUpdate,
          newConjoint: conjointValueUpdated,
          nbenfants: numEnfant !== "undefined" ? numEnfant : '-',
          nbascandants:numAscendants !== "undefined" ? numAscendants : '-',
          newNbenfants: numEnfantsUpdate!== "undefined" ? numEnfantsUpdate : '-',
          newNbascandants: numAscendingUpdate!== "undefined" ? numAscendingUpdate : '-',
          userDate: formatDateFR(parsedUser.date),
          comprenom: capitalize(firstName),
          comnom: capitalize(lastName),
        };
      } else {
        return {
          ...prevData,
          conjoint: conjoint || "non",
          newConjoint: numConjointsUpdate || conjointStatus,
          nbenfants: numEnfant !== "undefined" ? numEnfant : '0',
          nbascandants:numAscendants !== "undefined" ? numAscendants : '0',
          newNbenfants: numEnfantsUpdate!== "undefined" ? numEnfantsUpdate : '0',
          newNbascandants: numAscendingUpdate!== "undefined" ? numAscendingUpdate : '0',
          userDate: formatDateFR(parsedUser.date),
          comprenom: capitalize(firstName),
          comnom: capitalize(lastName),
        };
      }
      
       
      // return updatedData;
    });
  }

  const selectedChampsCookie = Cookies.get("selectedChamps");
  const selectedChamps = selectedChampsCookie ? JSON.parse(selectedChampsCookie) : [];
  const hasConjoint = selectedChamps.some(champ => champ.label && champ.label.includes("Conjoint"));
  const branchCookie = Cookies.get("branche");
  const formule = Cookies.get("setFormule");

      if (branchCookie.includes("Bureaux") && formule?.includes("Conjoint")) {
        const newConjointValue = hasConjoint ? 'oui' : 'non';
         setData(prevData => ({
          ...prevData,
          newConjoint: newConjointValue, 
        }));
      }

      if (branchCookie.includes("Habitat") && formule?.includes("Isolé")) {
         setData(prevData => ({
          ...prevData,
          newConjoint: conjointStatus, 
        }));
      }

     console.log("Nouveau state 'data':", data); 
}, []);


  return (
    <div>
        <div id="invoiceholder-tp2">
           <div id="template-2">
            <div className='space'>
              {" "}
            </div>
             <div className='tp2-title'>
               <h2>PROPOSITION TARIFAIRE santé</h2>
             </div>
              <div className="template2-top">
                <p>Références: {user.muna}</p>
                <br/>
                <p style={{fontSize: 13}}>Le {user.datecreation}</p>
              </div>
              <div className="tp2-body">
              {!nomBranches.includes("Architecte") && !nomBranches.includes("Bureaux") ? (
                <div className="tp2-champ1">
                    <h3 className="tab-title">Contrat collectif obligatoire en place</h3>
                    <p>Nom du contrat: Planet'santé {nomBranches}</p>
                    <table className="table-N">
                    <thead className="top-table">
                    <th className="col">Régime</th>
                    <th className="col">Option obligatoire</th>
                    <th className="col">Formule tarifaire</th>
                    <th className="col">Nombre d'enfants</th>
                    <th className="col">Nombre d'ascendants</th>
                    <th className="col">Conjoint</th>
                    </thead>
                    <tbody >
                    <td className="col"><i className="items">{data.regime}</i></td>
                    <td className="col"><i className="items">{data.optiobli}</i></td>
                    <td className="col"><i className="items">{data.formule}</i></td>
                    <td className="col"><i className="items">{data.formule.includes("Famille") && !data.nbenfants ? "-" : data.nbenfants}</i></td>
                    <td className="col"><i className="items">{data.nbascandants || "-"}</i></td>
                    <td className="col"><i className="items">{data.conjoint}</i></td>
                    </tbody>
                  </table>
                </div>
              ):(
                <div className="tp2-champ1">
                    <h3 className="tab-title">Contrat collectif obligatoire en place</h3>
                    <p>Nom du contrat: Planet'santé {nomBranches}</p>
                    <table className={!nomBranches.includes("Architecte") && !nomBranches.includes("Bureaux") ? "table-N" : "table-N2"}>
                    <thead className="top-table">
                    <th className="col">Régime</th>
                    <th className="col">Option obligatoire</th>
                    <th className="col">Formule tarifaire</th>
                    {!nomBranches.includes("Architecte") && (
                    <th className="col">Conjoint</th>
                    )}
                    </thead>
                    <tbody >
                    <td className="col"><i className="items">{data.regime}</i></td>
                    <td className="col"><i className="items">{data.optiobli}</i></td>
                    <td className="col"><i className="items">{Cookies.get("setFormule")}</i></td>
                    {!nomBranches.includes("Architecte") && (
                    <td className="col"><i className="items">{data.conjoint}</i></td>
                    )}
                    </tbody>
                  </table>
                </div>
              )}
              {!nomBranches.includes("Architecte") && !nomBranches.includes("Bureaux")   ? (
                <div className="tp2-champ2">
                  <h3 className="tab-title">Proposition pour la partie facultative</h3>
                  <table className="table-N">
                    <thead className="top-table">
                    <th className="col">Régime</th>
                    <th className="col">Option obligatoire</th>
                    <th className="col">Formule tarifaire</th>
                    <th className="col">Nombre d'enfants</th>
                    <th className="col">Nombre d'ascendants</th>
                    {!nomBranches.includes("Architecte") && (
                    <th className="col">Conjoint</th>
                    )}
                   </thead>
                   <tbody >
                    <td className="col"><i className="items">{data.regime || "-"}</i></td>
                    <td className="col"><i className="items">{data.newOptiobli|| "-"}</i></td>
                    <td className="col"><i className="items">{data.formule || "-"}</i></td>
                    <td className="col"><i className="items">{data.newNbenfants || "-"}</i></td>
                    <td className="col"><i className="items">{data.newNbascandants || "-"}</i></td>
                    {!nomBranches.includes("Architecte") && (
                    <td className="col"><i className="items">{data.newConjoint}</i></td>
                    )}
                   </tbody>
                 </table>
                 <p className="tarif">Votre tarif : <i>{percentage}% du PMSS soit {resultat || "0"} € (PMSS = 3864  € en 2024)</i></p>
                 </div>
              ):(
                <div className="tp2-champ2">
                  <h3 className="tab-title">Proposition pour la partie facultative</h3>
                  <table className={!nomBranches.includes("Architecte") && !nomBranches.includes("Bureaux") ? "table-N" : "table-N2"}>
                    <thead className="top-table">
                    <th className="col">Régime</th>
                    <th className="col">Option obligatoire</th>
                    <th className="col">Formule tarifaire</th>
                    {!nomBranches.includes("Architecte") && (
                    <th className="col">Conjoint</th>
              )}

                   </thead>
                   <tbody >
                    <td className="col"><i className="items">{data.regime || "-"}</i></td>
                    <td className="col"><i className="items">{data.newOptiobli|| "-"}</i></td>
                    <td className="col"><i className="items">{data.formule || "-"}</i></td>
                    {!nomBranches.includes("Architecte") && (
                    <td className="col"><i className="items">{data.newConjoint|| "-"}</i></td>
                    )}
                   </tbody>
                 </table>
                 { nomBranches.includes("Bureaux") ?
                                  <p className="tarif">Votre tarif : {resultat || "0"} €</p>
                 :
                                  <p className="tarif">Votre tarif : <i>{percentage}% du PMSS soit {resultat || "0"} € (PMSS = 3864  € en 2024)</i></p>
                 }
                </div> 
              )}
                <div className="tp2-champ3">
                    <p>Date d'effet souhaitée: <i style={{fontSize: 13}}> {new Date(user.date).toLocaleDateString('fr-FR', options)}</i></p>
                </div>
              </div>
              <div className="tp2-footer">
                <p>Notre proposition tient compte de la situation que vous avez déclarée, 
                des tarifs et des garanties en vigueur à ce jour. <br/> Ces tarifs sont revalorisés 
                chaque année sur décision du Conseil d'administration. <br/> Produit santé : le montant 
                de vos échéances pourra être différent en fonction de la date d'enregistrement du contrat. 
                Cela ne modifie pas votre cotisation annuelle.
                </p>
                <br/>
                <p>Si votre situation familiale change, la modification sera effectuée sur vos contrats 
                collectifs et individuels. <br/> Votre cotisation pourra, en fonction du changement, augmenter x
                ou baisser. Le tarif indiqué sera prélevé sur votre compte personnel.
                </p>
              </div>
            </div>   
        </div>
    </div>
  )
}

export default Template2