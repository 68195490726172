import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import Cookies from 'js-cookie';
import Alert from '@mui/material/Alert';
import Decimal from 'decimal.js';

function ConditionCaretPt({
    selectedFormule, selectedOption, isEcludeBranches, selectedChamps, handleAddChamp, getSelectedOptionFilterData,
    numsEnfants, handleChampChange, handleRemoveChamp, handleNumEnfantsChange, optionOptions, handleOptionChange,
    getChampOptions, showOption, showMessage, handleOption, handleMouseEnter, handleMouseLeave,
    getAvailableOptions, getdataFromFormule, setSelectedChamps
}) {

    const [selectOptions, setSelectOptions] = useState([]);
    
    const branchePTPC = Cookies.get("branche") === "PTPC"
   
    const [filterChamps, setFilterChamps] = useState([]);

  

   // Mise à jour des options disponibles en excluant celles déjà sélectionnées
   const updateSelectOptions = useCallback(() => {
    const availableChamps = getdataFromFormule();
    const filteredChamps = availableChamps.filter(availableChamp =>
        !selectedChamps.some(selectedChamp => selectedChamp.label === availableChamp.label)
    );

    // Ajouter une vérification pour éviter les mises à jour inutiles
    if (JSON.stringify(selectOptions) !== JSON.stringify(filteredChamps)) {
        setSelectOptions(filteredChamps);
        console.log("Options updated:", JSON.stringify(filteredChamps, null, 2));
    }
}, [getdataFromFormule, selectedChamps, selectOptions]);


useEffect(() => {
    updateSelectOptions();
}, [updateSelectOptions, selectedOption, selectedChamps]);




    const handleChampChanger = (selectedChamp, index) => {
        if (!selectedChamp) {
            console.error("Aucun champ sélectionné.");
            return;
        }

        const newChamp = {
            value: selectedChamp.id || selectedChamp.value,
            label: selectedChamp.nom || selectedChamp.label,
            valeur: selectedChamp.valeur
        };

        const updatedSelectedChamps = [...selectedChamps];
        updatedSelectedChamps[index] = newChamp;

        setSelectedChamps(updatedSelectedChamps);
    };

    const filteredChamps = selectedChamps.filter(champ => champ.label !== "Salarié");

    return (
        <div>
            {(( !selectedFormule?.nomFormule === "Isolé" && isEcludeBranches && Cookies.get("setOption") !== "Option 1") || (showOption && isEcludeBranches)) && (
                <>
                    <div>
                        <label className="select-label">Option :</label>
                        <Select
                            className="select-option"
                            options={optionOptions}
                            onChange={handleOptionChange}
                            placeholder="Choisissez une option..."
                        />
                    </div>

                    {selectedOption?.nomOption > "Option 1" && isEcludeBranches && (
                        <div className="formUpdate-body">
                            {selectedChamps.map((champ, index) => (
                                <div className="formUpdate-center" key={index}>
                                    <div className="formUpdate-select-center">
                                        <div className="formUpdate-select-pc">
                                            <label className="select-label champ-label">Personne couverte :</label>
                                            <Select
                                                className="select-champ"
                                                options={selectOptions}
                                                onChange={(selectedChamp) => handleChampChanger(selectedChamp, index)}
                                                placeholder="Choisissez un champ..."
                                                value={filteredChamps[index] || null}
                                            />
                                        </div>
                                        <button className="fromUpdate-btn-ayant-sup" onClick={() => handleRemoveChamp(index)}>Supprimer</button>
                                    </div>

                                    {selectedChamps[index] && selectedChamps[index].label && selectedChamps[index].label.includes("Enfant(s)") && (
                                        <div className="formUpdate-select-sup">
                                            <label className="select-label">Nombre d'enfants :</label>
                                            <Select
                                                value={numsEnfants[index]}
                                                onChange={(selectedOption) => handleNumEnfantsChange(index, selectedOption)}
                                                options={[{ value: 1, label: '1' }, { value: 2, label: '2 ou +' }]}
                                                placeholder="Choisissez le nombre d'enfants..."
                                                className="select-input"
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}

                            <div className="formUpdate-btn-option">
                                <button className="formUpdate-btn-addop1" onClick={handleAddChamp}>Ajouter un ayant-droit</button>
                            </div>
                        </div>
                    )}
                </>
            )}

            {isEcludeBranches && !showOption && selectedFormule?.nomFormule === "Isolé" && (
                <div className="formUpdate-body">
                {Cookies.get("setOption") !== "Option 3" && (
                    <div>
                        <div className="formUpate-body-option">
                            <div className="formUpdate-alert-container" style={{ height: '50px' }}>
                                {showMessage && (
                                    <Alert variant="filled" severity="info">
                                        Pour améliorer les options du salarié et de ces ayants-droits.
                                    </Alert>
                                )}
                            </div>
                            <div className="formUpdate-option-contaire">
                                <button className="formUpdate-option-btn" onClick={handleOption} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>Changer d'option</button>
                            </div>
                        </div>
                    </div>
                )}
                  

                    {selectedChamps.map((champ, index) => (
                        <div className="formUpdate-center" key={index}>
                            <div className="formUpdate-select-center">
                                <div className="formUpdate-select-pc">
                                    <label className="select-label champ-label">Personne couverte :</label>
                                    <Select
                                        className="select-champ"
                                        options={selectOptions.filter(pc => pc.label !== "Salarié")}
                                        onChange={(selectedChamp) => handleChampChanger(selectedChamp, index)}
                                        placeholder="Choisissez un champ..."
                                        value={selectedChamps[index] || null}
                                    />
                                </div>
                                <button className="fromUpdate-btn-ayant-sup" onClick={() => handleRemoveChamp(index)}>Supprimer</button>
                            </div>

                            {selectedChamps[index] && selectedChamps[index].label && selectedChamps[index].label.includes("Enfant(s)") && (
                                <div className="formUpdate-select-sup">
                                    <label className="select-label">Nombre d'enfants :</label>
                                    <Select
                                        value={numsEnfants[index]}
                                        onChange={(selectedOption) => handleNumEnfantsChange(index, selectedOption)}
                                        options={[{ value: 1, label: '1' }, { value: 2, label: '2 ou +' }]}
                                        placeholder="Choisissez le nombre d'enfants..."
                                        className="select-input"
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                    <div className="formUpdate-btn-option">
                        <button className="formUpdate-btn-addop1" onClick={handleAddChamp}>Ajouter un ayant-droit</button>
                    </div>
                </div>
            )}

            {selectedFormule?.nomFormule !== "Isolé" && isEcludeBranches && (
                <>
                    <div>
                        <label className="select-label">Option :</label>
                        <Select
                            className="select-option"
                            options={optionOptions}
                            onChange={handleOptionChange}
                            placeholder="Choisissez une option..."
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default ConditionCaretPt;
