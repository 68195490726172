import React, {createContext, useState, useEffect} from 'react';
import axios from 'axios';
import {API, graphqlOperation} from "aws-amplify";
import {
    createBranche,
    createChamps,
    createFormule,
    createOptions,
    createRegime,
    deleteBranche,
    updateBranche, 
    updateFormule,
    updateOptions,
    updateRegime,
    updateChamps
} from './../graphql/mutations';
import {
    champsByRegime,
    forumlesByBranche,
    getBranche, getFormule,
    listBranches,
    optionsByFormule,
    regimeByOptions
} from "../graphql/queries";


export const BranchContext = createContext();

const RequestActionEnum = {
    Generale: 'General',
    Alsace_Moselle: 'Alsace_Moselle'
}

export const BranchProvider = (props) => {
    const [branchData, setBranchData] = useState([]);
    const fetchBranchData = async () => {
        try {
            const response = await API.graphql(graphqlOperation(listBranches));
            const branchItems = response.data.listBranches.items;

            const branchesArray = await Promise.all(branchItems.map(async (branch) => {
                try {
                    const res = await API.graphql(graphqlOperation(getBranche, { id: branch.id }));
                    return res.data.getBranche;
                } catch (error) {
                    console.error(`Erreur lors de la récupération de la branche ${branch.id}:`, error);
                    return null;
                }
            }));

            setBranchData(branchesArray.filter(branch => branch !== null));
        } catch (error) {
            console.error('Erreur avec l\'API "branch" : ', error);
        }
    };
    useEffect(() => {
        fetchBranchData();
    }, []);

    const getBranch = async (id) => {
        const res = await API.graphql(
            graphqlOperation(
                getBranche,
                {id}
            ));
        return res.data.getBranche;
    }

    const createBranch = async (newBranch) => {
        try {
            await axios.post(`${document.URL}branch`, newBranch);
            fetchBranchData();
        } catch (error) {
            console.error('Erreur avec l\'API "branch" lors de la création : ', error);
        }
    }

    const updateBranch = async (branchId, updatedData) => {
        try {
            // Update the branch
            await API.graphql(
                graphqlOperation(updateBranche, {
                    input: {
                        id: branchId,
                        nomBranche: updatedData.nomBranche,
                    }
                }));
    
            for (let formule of updatedData.formules.items) {
                // Update the formule for each branch
                await API.graphql(
                    graphqlOperation(updateFormule, {
                        input: {
                            id: formule.id,
                            nomFormule: formule.nomFormule,
                            brancheId: branchId
                        }
                    }));
    
                for (let option of formule.options.items) {
                    // Update an option for each formule
                    await API.graphql(
                        graphqlOperation(updateOptions, {
                            input: {
                                id: option.id,
                                nomOption: option.nomOption,
                                formuleId: formule.id
                            }
                        }));
    
                    for (let regime of option.regimes.items) {
                        // Update a regime for each option
                        await API.graphql(
                            graphqlOperation(updateRegime, {
                                input: {
                                    id: regime.id,
                                    nomRegime: regime.nomRegime,
                                    optionId: option.id
                                }
                            }));
    
                        for (let champ of regime.champs.items) {
                            // Update a champ for each regime
                            await API.graphql(graphqlOperation(
                                updateChamps, {
                                    input: {
                                        id: champ.id,
                                        nom: champ.nom,
                                        valeur: champ.valeur,
                                        regimeId: regime.id
                                    }
                                }));
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour:', error);
            throw error;
        }
    };
    

    const deleteBranch = async (branchId) => {
        try {
            // await axios.delete(`${document.URL}branch/${branchId}`);
            await API.graphql(graphqlOperation(deleteBranche, {input: {id: branchId}}));
            fetchBranchData();
        } catch (error) {
            console.error('Erreur avec l\'API "branch" lors de la suppression : ', error);
        }
    }

    return (
        <BranchContext.Provider
            value={{branchData, fetchBranchData, getBranch, createBranch, updateBranch, deleteBranch}}>
            {props.children}
        </BranchContext.Provider>
    );
}
