import Decimal from 'decimal.js';
import Cookies from 'js-cookie';

const PMSS = 3864 ; // Constante
const excludedBranches = ["Cartonnage", "PTPC"]; 
const numbreEnfants = parseInt(Cookies.get('numEnfants') || 0, 10); // Nombre d'enfants récupéré du cookie

export function computePricingFromPricingType(pricingData) {
    let total = new Decimal(0);
    const typePersonne = pricingData?.typePersonneCouverte?.valeur || [];
    //console.log("Données de typePersonneCouverte:", JSON.stringify(typePersonne, null, 2));
    if (!Array.isArray(typePersonne)) {
        console.error("typePersonneCouverte.valeur n'est pas un tableau ou est indéfini.");
        return total;
    }
    const getCookieNumber = (cookieName) => {
        const value = Cookies.get(cookieName);
        return value ? parseFloat(value.replace(',', '.')) : 0;
    };
    
    const getValidValueFromArray = (array) => {
        for (let item of array) {
            if (item && item.value !== undefined && item.label !== undefined) {
                return item.value;
            }
        }
        return null;
    }

    console.log("pricingData donnée :", JSON.stringify(pricingData, null,2))

    const excludedFormule = pricingData.formule?.valeur.includes("Isolé")
    
  
    const numEnfantsValueArray = pricingData?.numEnfants?.valeur?.filter(item => item !== null) || [];
    const numEnfants = getValidValueFromArray(numEnfantsValueArray) !== null
        ? getValidValueFromArray(numEnfantsValueArray)
        : getCookieNumber("numEnfants");
    //console.log(`Number of children (numEnfants) used for calculation: ${numEnfants}`);
    
    const numAscendantsValueArray = pricingData?.numAscendants?.valeur?.filter(item => item !== null) || [];
    const numAscendants = getValidValueFromArray(numAscendantsValueArray) !== null
        ? getValidValueFromArray(numAscendantsValueArray)
        : getCookieNumber("numAscendants");
    //console.log(`Number of ascendants (numAscendants) used for calculation: ${numAscendants}`);

    const hasSpecifiedName = (personne, name) => {
        return (personne?.nom && personne.nom.includes(name)) || (personne?.label && personne.label.includes(name));
    }

    const hasConjointLabel = typePersonne.some(personne => hasSpecifiedName(personne, 'Conjoint'));
    const conjoint = hasConjointLabel ? 1 : 0;
    //console.log(`Conjoint value used for calculation: ${conjoint}`);
    const branchName = Cookies.get('branche'); // Récupération du nom de la branche depuis un cookie
    const isBranchExcluded = excludedBranches.includes(branchName);
    const selectedOption = parseInt(Cookies?.get("updateSelectedOption")?.replace("Option ", ""), 10); // Extrait le numéro de l'option// Exemple de branches exclues

    const specifiedNames = ['Enfant(s)', 'Ascendant(s)'];

    const hasEuroValue = pricingData.typePersonneCouverte.valeur.some(personne => {
        // Cas où `personne` a directement `typeValeur`
        if (personne.typeValeur === "EURO") {
            return true;
        }
        
        // Cas où `personne.id` (ou `personne` lui-même si `personne.id` n'existe pas) est un tableau d'objets contenant `typeValeur`
        const valeurs = personne.id ? (Array.isArray(personne.id) ? personne.id : [personne.id]) : [personne];
        return valeurs.some(v => v.typeValeur === "EURO");
    });
    
    const extensions = typePersonne.filter(personne => 
        personne.label && (personne.label.includes("Extension Duo") || personne.label.includes("Extension Famille"))
    );
    
    const hasExtension = typePersonne.some(personne => {
        return (personne.nom && personne.nom.includes("Extension")) || (personne.label && personne.label.includes("Extension"));
    });
        const salarie = typePersonne.find(personne => personne.label === "Salarié") || typePersonne.find(personne => personne.nom === "Salarié") ;

  
    if (hasEuroValue) {
        let total = new Decimal(0);
    
        pricingData.typePersonneCouverte.valeur.forEach(item => {
            // Traitement lorsque 'id' est directement l'objet à traiter
            if (item.id && item.id.typeValeur === "EURO") {
                let valeur = new Decimal(item.id.valeur.replace(',', '.') || 0);
                total = total.plus(valeur);
            }
            // Traitement lorsque 'id' contient plusieurs éléments
            else if (item.id && typeof item.id === 'object') {
                // On traite le cas où 'id' est un objet contenant des informations imbriquées
                Object.values(item.id).forEach(subItem => {
                    if (subItem.typeValeur === "EURO") {
                        let valeur = new Decimal(subItem.valeur.replace(',', '.') || 0);
                        total = total.plus(valeur);
                    }
                });
            }
            // Traitement des cas où l'élément lui-même contient les informations nécessaires
            else if (item.typeValeur === "EURO") {
                let valeur = new Decimal(item.valeur.replace(',', '.') || 0);
                total = total.plus(valeur);
            }
        });
    
        //console.log(`Total calculé: ${total.toFixed(2)}`);
        return total.toFixed(2);
    }

    if (isBranchExcluded && excludedFormule) {
        // Filtrer les données pour enfants et autres
        const enfantsData = pricingData.typePersonneCouverte.valeur.filter(personne =>
            personne && personne.label && personne.label.includes("Enfant(s)")
        );
        const autresData = pricingData.typePersonneCouverte.valeur.filter(personne =>
            personne && personne.label && !personne.label.includes("Enfant(s)")
        );

        // Calculer la somme pour les enfants
        let sommeEnfants = enfantsData.reduce((acc, curr) => acc.plus(new Decimal(curr.valeur.replace(',', '.'))), new Decimal(0));

        // Si numEnfants >= 2, multiplier la somme des enfants par le nombre d'enfants
        if (numEnfants >= 2) {
            sommeEnfants = sommeEnfants.times(numEnfants);
        }

        // Calculer la somme pour les autres types
        let sommeAutres = autresData.reduce((acc, curr) => acc.plus(new Decimal(curr.valeur.replace(',', '.'))), new Decimal(0));

        // Additionner les sommes des enfants et des autres, puis appliquer le PMSS
        total = sommeEnfants.plus(sommeAutres).div(100).times(PMSS);

        //console.log(`Total calculé pour la branche spéciale (${branchName}): ${total.toFixed(2)}`);
        return total.toFixed(2);
    }

    

 


    if (!hasEuroValue){
   if(!isBranchExcluded && hasExtension){
// Cas 6: Calcul pour "Extension" avec présence de "Salarié"
if (typePersonne.length > 1 && !typePersonne.some(personne => specifiedNames.some(name => hasSpecifiedName(personne, name))) && salarie) {
    let totalExtensions = typePersonne
        .filter(personne => personne && personne.nom && personne.nom.includes("Extension"))
        .reduce((acc, curr) => acc.plus(new Decimal(curr.valeur.replace(',', '.') || 0)), new Decimal(0));
    
    const salarie = typePersonne.find(personne => personne && personne.nom === "Salarié");
    let valeurSalarie = new Decimal(salarie && salarie.valeur ? salarie.valeur.replace(',', '.') : 0);

    // La formule reste la même
    total = totalExtensions.minus(valeurSalarie);

    // Logiques d'affichage pour vérifier les calculs
    console.log(`Total calculé pour le cas 6 avec soustraction du salarié: ${total.toString()}`);

    // Application au PMSS si nécessaire
    total = total.div(100).times(PMSS); // Convertir le pourcentage en valeur absolue basée sur le PMSS
    console.log(`Valeur finale après application au PMSS: ${total.toFixed(2)}`);
    
    if (Cookies.get("setOption") === "Option 1" && pricingData?.option.valeur === "Option 2" &&
        Cookies.get("branche").includes("Habitat") &&  !Cookies.get("setFormule").includes("Famille")) {
        let total = pricingData.typePersonneCouverte.valeur
        .reduce((acc, curr) => {
            if (curr && curr.valeur && (curr.label.includes("Extension") || curr.label === "Salarié")) {
                let valeur = new Decimal(curr.valeur.replace(',', '.') || 0);
                return acc.plus(valeur);
            }
            return acc;
        }, new Decimal(0));

    console.log(`Total calculé avant application au PMSS: ${total.toString()}`);

    // Convertir le pourcentage en valeur absolue basée sur le PMSS
    total = total.div(100).times(PMSS);
    console.log(`Valeur finale après application au PMSS: ${total.toFixed(2)}`);

    return total.toFixed(2);
    }
    
    return total;

    
}else if (extensions.length > 1 && !salarie) {
    let totalExtensions = extensions.reduce((acc, curr) => {
        let valeurExtension = curr.valeur ? new Decimal(curr.valeur.replace(',', '.') || '0') : new Decimal(0);
        return acc.plus(valeurExtension);
    }, new Decimal(0));

    // Application de cette somme au PMSS pour obtenir la valeur finale
    total = totalExtensions.div(100).times(PMSS);
    console.log(`Valeur finale pour multiples "Extensions Famille" sans "Salarié": ${total.toFixed(2)}`);
    return total.toFixed(2);
}
   }



// Cas 1: Une seule valeur dans typePersonneCouverte
if (typePersonne.length === 1 && !typePersonne.some(personne => hasSpecifiedName(personne, 'Enfant(s)') || hasSpecifiedName(personne, 'Ascendant(s)'))) {
    const value = parseFloat(typePersonne[0]?.valeur?.replace(',', '.') || 0);
    total = total.plus(new Decimal(value / 100 * PMSS).toFixed(2));
    //console.log(`Total calculated for case 1: ${total}`);

    return total;
}

    // Cas 2: Plusieurs valeurs, mais pas 'enfant' ni 'ascendant'
    if (typePersonne.length > 1 && !typePersonne.some(personne => {
        return specifiedNames.some(name => hasSpecifiedName(personne, name));
    })) {
        const subTotal = typePersonne.reduce((acc, personne) => {
            return acc.plus(new Decimal(personne?.valeur?.replace(',', '.') || 0));
        }, new Decimal(0));
        total = total.plus(subTotal.dividedBy(100).times(PMSS));
        console.log(`Total calculated for case 2: ${total}`);
        return total;
    }
    

    // Cas 3: Contient au moins un des noms 'enfant' ou 'ascendant' ou possède numEnfants ou numAscendants
    if (typePersonne.some(personne => {
        return specifiedNames.some(name => hasSpecifiedName(personne, name));
    }) || numEnfants > 0 || numAscendants > 0) {
        computeCase3(typePersonne, numEnfants, conjoint, numAscendants);
        console.log(`Total calculated for case 3: ${total}`);
        return total;
    }

    function computeCase3(typePersonne, numEnfants, conjoint, numAscendants) {
        let subTotal = new Decimal(0);
        typePersonne.forEach(personne => {
            let valeur = new Decimal(personne?.valeur?.replace(',', '.') || 0);
            if (hasSpecifiedName(personne, 'Enfant(s)')) {
                subTotal = subTotal.plus(valeur.times(numEnfants));
            } else if (hasSpecifiedName(personne, 'Conjoint')) {
                subTotal = subTotal.plus(valeur.times(conjoint));
            } else if (hasSpecifiedName(personne, 'Ascendant(s)')) {
                subTotal = subTotal.plus(valeur.times(numAscendants));
            } else {
                subTotal = subTotal.plus(valeur);
            }
        });
        total = total.plus(subTotal.dividedBy(100).times(PMSS));
    }




// Cas 4: Contient uniquement 'Enfant(s)' ou 'Ascendant(s)'
if (typePersonne.some(personne => hasSpecifiedName(personne, 'Enfant(s)')) && !typePersonne.some(personne => hasSpecifiedName(personne, 'Ascendant(s)'))) {
    return computeCase4(typePersonne, numEnfants);
} else if (!typePersonne.some(personne => hasSpecifiedName(personne, 'Enfant(s)')) && typePersonne.some(personne => hasSpecifiedName(personne, 'Ascendant(s)'))) {
    return computeCase4(typePersonne, numAscendants);
}

function computeCase4(typePersonne, num) {
    let subTotal = new Decimal(0);
    typePersonne.forEach(personne => {
        let valeur = new Decimal(personne?.valeur?.replace(',', '.') || 0);
        if (hasSpecifiedName(personne, 'Enfant(s)')) {
            subTotal = subTotal.plus(valeur.times(num));
        } else {
            subTotal = subTotal.plus(valeur);
        }
    });
    console.log(`Total calculated for case 4: ${total}`);

    total = total.plus(subTotal.dividedBy(100).times(PMSS));
    return total;
}
    }}
