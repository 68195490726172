import React from 'react'
import Navbar from '../components/Navbar'
import "./css/YourProfile.css"

function YourProfile() {
  return (
    <>
    <Navbar/>
        <div className="profile">
            <h2 className="profile-title">Mon Profil</h2>
            <div className="profile-container">
                <div className="form-section">
                    <form className="profile-form">
                        <h3 className='profile-subtitle'>Mes information : </h3>
                        <label className="profile-label">Prenom</label>
                        <input className="profile-input"/>
                        <label className="profile-label">Nom :</label>
                        <input className="profile-input"/>
                        <label className="profile-label">Email : </label>
                        <input className="profile-input"/>
                        <button className="profile-btn">Enregister</button>
                    </form>

                    {/*<form className="password-form">*/}
                    {/*    <h3 className='profile-subtitle'>Changer de Mot de Passe</h3>*/}
                    {/*    <label className="profile-label">Mot de Passe actuel: </label>*/}
                    {/*    <input className="profile-input"/>*/}
                    {/*    <label className="profile-label">Nouveau Mot de Passe :</label>*/}
                    {/*    <input className="profile-input"/>*/}
                    {/*    <label className="profile-label">Confirmation du Nouveau Mot de Passe : </label>*/}
                    {/*    <input className="profile-input"/>*/}
                    {/*    <button className="profile-btn">Enregister</button>*/}
                    {/*</form>*/}
                </div>
                <div className="pmss-section">
                    <ul className="pmss-list">
                        <li className="pmss-item"><h4 className="pmss-title">PMSS : (actuel "{"3666"}€") </h4></li>
                        <li className="pmss-item">Prix{" "}<input className="pmss-input"/>{" "}€ </li>
                        <li className="pmss-item"><button className="pmss-btn">Enregister</button></li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default YourProfile
