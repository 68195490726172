import React , {useContext, createContext, useState} from "react"

const UserContext = createContext(
    {user: null}
)

export const UserContextProvider =  ({children}) => {
    const [user, setUser ] = useState()

    return <UserContext.Provider value= {{user, setUser}}>
        {children}
    </UserContext.Provider>
}

export const useUserContext = () => {
    return useContext(UserContext)
}