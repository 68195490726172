import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import "./css/forgotPassword.css"
import { Auth } from 'aws-amplify';


const initialState = {
    email: "",
};
function ForgotPassword() {
    const [formData, setFormdata] = useState(initialState);
    const handleChange = (e) => {
        setFormdata({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const navigate = useNavigate()

    const handleResetPassword = () => {
        Auth.forgotPassword(formData.email)
            .then(data => console.log(data))
            .catch(err => console.log(err));
        navigate("/resetUsingVerificationCode")
    }
  return (
    <div className="container">
    <div className="container-forgot">
      <div className="wrap-forgot">
        <div className='forgot-header'>

        <h2 className="forgot-form-title"> Mot de passe oublié </h2>
        </div>
        <form className="forgot-form">
            <label className='form-label'>Adresse email</label>
            <input  value={formData.email}
                    type="text"
                    name="email"
                    className="forgot-input"
                    placeholder='xxxx@xxx.com'
                    onChange={handleChange}
                    required
            />
            <button
                onClick={handleResetPassword}
                className="forgot-form-btn">Se connecter
            </button>
        </form>
      </div>
    </div>
  </div>
  )
}

export default ForgotPassword
