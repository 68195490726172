import React, { useState, useEffect } from "react";
import "./css/formClient.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Cookies from "js-cookie";
import MessageSupport from "./composant/MessageSupport";


const initialState = {
  civilite: "",
  muna: "",
  nom: "",
  prenom: "",
  adress: "",
  cp: "",
  ville: "",
  date: new Date().toLocaleDateString("fr-FR"),
  datecreation: `${moment().format("DD/MM/YYYY")}`,
};

function FormClient() {
  const [user, setUser] = useState(initialState);
  const navigate = useNavigate();

  useEffect(() => {
    // Pour l'input 'date', définir par défaut le premier jour du mois suivant
    const nextMonthFirstDay = new Date();
    nextMonthFirstDay.setMonth(nextMonthFirstDay.getMonth() + 1);
    nextMonthFirstDay.setDate(1);

    setUser(prevUser => ({
      ...prevUser,
      date: nextMonthFirstDay.toISOString().split('T')[0]
    }));
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;
  
    if (name === "muna") {
      value = value.replace(/[^0-9.]+/g, ''); 
      if (value.length === 8 && !value.includes('.')) {
        value = value.slice(0, 8) + '.' + value.slice(8);
      }
      value = value.slice(0, 11); 
    }
  
    if (name === "nom" || name === "prenom") {
      value = value.replace(/[^a-zA-ZàâäéèêëïîôöùûüçÀÂÄÉÈÊËÏÎÔÖÙÛÜÇ\s]+/g, ''); 
      value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); 
    }
  
    if (name === "nom") {
      value = value.toUpperCase(); 
    }  


    if (name === "cp") {
      value = value.replace(/[^0-9]+/g, ''); 
      value = value.slice(0, 5); 
    }
  
    setUser({ ...user, [name]: value });
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      !user.civilite ||
      !user.muna ||
      !user.nom ||
      !user.prenom ||
      !user.adress ||
      !user.cp ||
      !user.ville ||
      !user.date
    ) {
      toast.error(`veulliez remplir tous les champs ${""}`);
    } else {
      Cookies.set("user", JSON.stringify(user), { expires: 1 })
      navigate("/step4");
    }
    if (user.muna.length <= 10) {
      toast.error("votre champ : Muna doit contenir 10 chiffres");
      navigate("/step3");
    }
  };

  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  });

  return (
    <div className="formulaire-container">
      <div className="formulaire-header">
        <span className="formulaire-title"></span>
        <h2 className="formulaire-subtitle">
          Renseignez les champs suivants pour obtenir un devis
          <button onClick={handleClick} className="btn-deco">
            {" "}
            Se déconnecter
          </button>
        </h2>
      </div>
      <div className="formclient-body">
        <form className="formClient-container" onSubmit={handleSubmit}>
          <label className="fromClient-form-label">Civilité</label>
          <select
            className="from-input"
            name="civilite"
            value={user.civilite}
            onChange={handleChange}
          >
            <option>--</option>
            <option value="Madame">Madame</option>
            <option value="Monsieur">Monsieur</option>
          </select>
          <label className="fromClient-form-label">MUNA</label>
          <input
            className="from-input"
            placeholder="ex:  XXXXXX.XX"
            name="muna"
            value={user.muna}
            type="text"
            onChange={handleChange}
          />
          <label className="fromClient-form-label">Nom</label>
          <input
            className="from-input"
            name="nom"
            value={user.nom}
            type="text"
            onChange={handleChange}
          />
          <label className="fromClient-form-label">Prénom</label>
          <input
            className="from-input"
            name="prenom"
            value={user.prenom}
            type="text"
            onChange={handleChange}
          />
          <label className="fromClient-form-label">Adresse</label>
          <input
            style={{ marginBottom: 25 }}
            className="from-input"
            name="adress"
            value={user.adress}
            type="text"
            onChange={handleChange}
          />
          <div className="region">
            <label className="fromClient-form-label-city">Code Postal</label>
            <input
              type="text"
              maxLength={5}
              className="from-input-cp"
              name="cp"
              value={user.cp}
              onChange={handleChange}
            />
            <label className="fromClient-form-label-city">Ville</label>
            <input
              className="from-input-ct"
              name="ville"
              value={user.ville}
              type="text"
              onChange={handleChange}
            />
          </div>
          <label className="fromClient-form-label">Date d'effet souhaitée</label>
          <input
            className="from-input"
            type="date"
            name="date"
            value={user.date}
            onChange={handleChange}
          />
          <button className="formClient-btn" type="submit">
            Suivant
          </button>
        </form>
      </div>
      <MessageSupport/>
    </div>
  );
}

export default FormClient;
