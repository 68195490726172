import React from 'react'
import Select from 'react-select';


function ConditionArch({selectedFormule, selectedOption, selectedBranch,optionsFiltrees,handleExtensionChange }) {
  return (
    <div>
          {selectedFormule?.nomFormule === "Isolé" && selectedOption?.nomOption === "Option 2" && selectedBranch.includes("Architecte") && (
                <>
                <label className="select-label champ-label">Personne couverte :</label>

                <Select
                    className="select-champ"
                    options={optionsFiltrees} // Assurez-vous que c'est la liste des options disponibles pour la sélection
                    onChange={(selectedChamp) =>   handleExtensionChange(selectedChamp)} // Utilisez la fonction handleExtensionChange pour gérer la sélection
                    placeholder="Sélectionnez une extension..."
                    isSearchable={true} // Optionnel : rend le champ de sélection recherchable
                />
                </>
            )}

    </div>
  )
}

export default ConditionArch
