import React, { useState } from 'react';
import './css/help.css';

function HelpPage() {
    const [zoomed, setZoomed] = useState(false);

    const handleZoom = () => {
        setZoomed(!zoomed);
    };

    return (
        <div className="container-help">
            <h1 className="header1">Assistance pour le deviseur PRODIGEO</h1>

            <h2 className="header2">Première connexion</h2>
            <p>Un compte vous a été crée pour que vous puissiez accéder au deviseur PRODIGEO.</p>
            <p>Un mail provenant de l’adresse <u>no-reply@verificationemail.com</u> a dû vous parvenir et peut se trouver dans vos spams.</p>
            <ol>
                <li className="listItem">Cliquez sur le lien présent sur ce mail pour valider votre compte.</li>
                <li className="listItem">Votre compte est validé, il suffit de vous rendre sur <b>« Mot de passe oublié »</b> pour choisir votre mot de passe.</li>
                <li className="listItem">Vous pouvez à présent vous connecter sur le deviseur avec votre mot de passe choisi.</li>
            </ol>

            <h2 className="header2">Instructions d'utilisation :</h2>
            <p>La simulation d’un tarif se réalise en 4 étapes :</p>
            <ol>
                <li className="listItem">Situation actuelle du salarié (contrat collectif obligatoire mis en place par l’entreprise)</li>
                <li className="listItem">Choix des modifications souhaitées par le salarié en facultatif (ajout de bénéficiaires, changement d’option) et affichage du tarif pour le complément choisi par le salarié</li>
                <li className="listItem">Saisie des informations du salarié</li>
                <li className="listItem">Affichage du devis pour téléchargement</li>
            </ol>

            <h2 className="header2">FAQ :</h2>
            <ul>
                <li className="listItem"><strong>J'ai oublié mon mot de passe ?</strong> Cliquez sur le lien "Mot de passe oublié" présent sur la page de connexion et de suivre les instructions indiquées.</li>
                <li className="listItem"><strong>Le fichier PDF en fin de simulation ne s'affiche pas ou ne se télécharge pas sur mon poste ?</strong> Autorisez l'affichage des pop-ups et redirections sur votre navigateur.</li>
            </ul>

            <h2 className="header2">Dépannage :</h2>
            <p>Si vous rencontrez des problèmes avec deviseur PRODIGEO, essayez les étapes suivantes :</p>
            <ol>
                <li className="listItem">Nettoyez le cache de votre navigateur web.</li>
                <li className="listItem">Consultez notre FAQ pour des solutions aux problèmes courants.</li>
                <li className="listItem">Ouvrir une demande d'assistance dans <a className="link" target="_blank" href="https://probtp.service-now.com/hello?id=index">HELLO</a>.</li>
            </ol>
            <img src={`${process.env.PUBLIC_URL}support.png`} alt="Support" className={`image supportImage ${zoomed ? 'zoomedIn' : ''}`} onClick={handleZoom} />
        </div>
    );
}

export default HelpPage;
