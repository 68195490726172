import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import "./css/forgotPassword.css"
import {Auth} from 'aws-amplify';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
    email: "",
    verificationCode: "",
    newPassword: "",
    confirmPassword: "",
};


function ResetPasswordUsingVerificationCode() {
    const [formData, setFormdata] = useState(initialState);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value });
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (formData.newPassword !== formData.confirmPassword) {
            toast.error("Les mots de passe ne correspondent pas.");
            return;
        }

        if (!isPasswordValid(formData.newPassword)) {
            return;
        }

        try {
            await Auth.forgotPasswordSubmit(
                formData.email, 
                formData.verificationCode, 
                formData.newPassword
            );
            toast.success("Mot de passe réinitialisé avec succès !");
            navigate("/");
        } catch (err) {
            console.log(err);
            toast.error(`Une erreur est survenue : ${err.message}`);
        }
    };

    const isPasswordValid = (password) => {
        let errorMessage = "";
        if (password.length < 8) {
            errorMessage = "Le mot de passe doit contenir au moins 8 caractères.";
        } else if (!/[a-z]/.test(password)) {
            errorMessage = "au moins une lettre minuscule";
        } else if (!/[A-Z]/.test(password)) {
            errorMessage = "au moins une lettre majuscule";
        } else if (!/\d/.test(password)) {
            errorMessage = "au moins un chiffre";
        } else if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) {
            errorMessage = "au moins un caractère spécial";
        }

        if (errorMessage !== "") {
            toast.error(`Le mot de passe doit contenir ${errorMessage}.`);
            return false;
        }

        return true;
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    return (
        <div className="container">
            <div className="container-forgot">
                <div className="wrap-forgot">
                    <div className='forgot-header'>

                        <h2 className="forgot-form-title"> Entrez votre nouveau mot de passe </h2>
                    </div>
                    <form className="forgot-form">
                        <label className='form-label'>Adresse email</label>
                        <input value={formData.email}
                               type="text"
                               name="email"
                               className="forgot-input"
                               placeholder='xxxx@xxx.com'
                               onChange={handleChange}
                               required
                        />
                        <label className='form-label'>Code de vérification (envoyé sur votre boite email)</label>
                        <input value={formData.verificationCode}
                               type="text"
                               name="verificationCode"
                               className="forgot-input"
                               placeholder='12456'
                               onChange={handleChange}
                               required
                        />
                        <label className='form-label'>Nouveau mot de passe</label>
                        <div className="rein-input-password">
                        <input
                            value={formData.newPassword}
                            type={showNewPassword ? "text" : "password"}
                            name="newPassword"
                            onChange={handleChange}
                            className="forgot-input-password"
                            required
                            onBlur={() => isPasswordValid(formData.newPassword)}
                            />
                        <IconButton
                                    onClick={toggleNewPasswordVisibility}
                                    aria-label="toggle confirm password visibility"
                                    edge="end"
                                >
                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                </div>

                        <label className='form-label'>Confirmer le mot de passe</label>
                        <div className="rein-input-password">
                        <input
                            value={formData.confirmPassword}
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            placeholder="******"
                            onChange={handleChange}
                            required
                            className="forgot-input-password"
                            onBlur={() => {
                                if (formData.newPassword !== formData.confirmPassword) {
                                    toast.error("Les mots de passe ne correspondent pas.");
                                }
                            }}
                            />
                                <IconButton
                                    onClick={toggleConfirmPasswordVisibility}
                                    aria-label="toggle confirm password visibility"
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </div>
                               <div className='forgot-span'>
                                <span className='forgot-span-text' >Le mot de passe doit contenir au moins 8 caractères, incluant un chiffre, une lettre majuscule, une lettre minuscule et un caractère spécial.</span>
                               </div>
                                   

                        <button
                            onClick={handleResetPassword}
                            className="forgot-form-btn">Se connecter
                        </button>
                    </form>
             
                </div>
            </div>
            <ToastContainer position="right" autoClose={5000} />
        </div>
    )
}

export default ResetPasswordUsingVerificationCode
