import React, {useCallback, useContext, useEffect, useState} from "react";
import HeaderPage from "./composant/HeaderPage";
import {BranchContext} from "../Context/Branche";
import {useNavigate, useParams} from "react-router-dom";
import "./css/formUpdate.css";
import Tableau from "./composant/Tableau";
import Select from 'react-select';
import Cookies from "js-cookie";
import {computePricingFromPricingType} from "../utils/computerPrincing";
import { toast} from "react-toastify"
import Alert from '@mui/material/Alert';
import MessageSupport from "./composant/MessageSupport";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import computerSwitchCase from "../utils/computeSwucthCase";
import ConditionHab from "./composant/Conditionement/ConditionHab";
import ConditionArch from "./composant/Conditionement/ConditionArch";
import ConditionCaretPt from "./composant/Conditionement/ConditionCaretPt";
import ConditionBet from "./composant/Conditionement/ConditionBet";
import { Cookie } from "@mui/icons-material";
import { updateChamps } from "../graphql/mutations";

function FormUpdate() {
    const {getBranch} = useContext(BranchContext);
    const {id} = useParams();
    const [computedPrice, setComputedPrice] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [showOption, setShowOption] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [branch, setBranch] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedFormule, setSelectedFormule] = useState(null);
    const [selectedChamps, setSelectedChamps] = useState([]);
    const [champs, setChamps] = useState([]);
    const [conjoint, setConjoint] = useState(" ");
    const [numsEnfants, setNumsEnfants] = useState([]); 
    const [numEnfantsCookie, setNumEnfantsCookie] = useState([]);
    const [numsAscendants, setNumsAscendants] = useState([]); 
    const [numAscendantsCookie, setNumAscendantsCookie] = useState([]);
    const [isArchitecte, setIsArchitecte] = useState("")
    const [showSalarié, setShowSalarié] = useState(false); 
    const [architecteChampOptions, setArchitecteChampOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedRegime, setSelectedRegime] = useState(null);
    const [selectedChamp, setSelectedChamp] = useState(null);
    const [isConjoint, setIsConjoint] = useState(false);
    const [attemptedProcessing, setAttemptedProcessing] = useState(false);
    const [currentChamps, setCurrentChamps] = useState(selectedChamps);
    const [champsUpdated , setChampsUpdated] = useState([])
    const [salared, setSalared] = useState([])

    const navigate = useNavigate();
    const excludedeBranches = ["Cartonnage", "PTPC"]      
    const isEcludeBranches = excludedeBranches.includes(selectedBranch);
   
    useEffect(() => {
        const fetchData = async () => {
            try {
                const branchData = await getBranch(id);
                setBranch(branchData);

                const selectedFormuleId = Cookies.get("setFormuleId");
                if (selectedFormuleId && branchData?.formules?.items) {
                    const formuleFromCookie = branchData.formules.items.find(formule => formule.id === selectedFormuleId);
                    setSelectedFormule(formuleFromCookie);
                }

                
                
                let champsData = []; // Définition initiale vide
            
                const champsCookie = Cookies.get("selectedChamps");
                if (champsCookie) {
                    champsData = JSON.parse(champsCookie);
                    const champsNames = champsData.map(champ => ({ nom: champ.label || champ.nom }));
                    setChamps(champsNames);
    
                    //console.log("Champs:", JSON.stringify(champsData, null, 2));
                }
                

                
                ////console.log("mes donnée : " + JSON.stringify(branch, null, 2));
                //console.log("formule data" + JSON.stringify(selectedFormule, null, 2));
            } catch (error) {
                console.error("Erreur lors de la récupération des données de la branche:", error);
            }
        };
        
        fetchData();
    }, [id, getBranch]);

   
    
    useEffect(() => {
        const  loadData = async () => {
        const selectedFormuleCookie = Cookies.get("setFormule");
        const selectedRegimeCookie = Cookies.get("setRegime");
        const selectedOptionCookie = Cookies.get("setOption");
        const numEnfantCookieValue = Cookies.get("numEnfants");
        const numAscendantsCookieValue = Cookies.get("numAscendants");
        const brancheCookie = Cookies.get('branche');

        const { optionData, regimeData, champsData } = getSelectedOptionFilterData();
        getdataFromFormule()
        
        
        if(numEnfantCookieValue > 0 ) { 
        const parsedNumEnfantCookie = numEnfantCookieValue ? JSON.parse(numEnfantCookieValue) : 0;
        const numEnfantsCookie = {
            value: parsedNumEnfantCookie,
            label: parsedNumEnfantCookie.toString()
        };
        setNumEnfantsCookie([numEnfantsCookie]);
        ////console.log("enfant:", numEnfantsCookie);
        }
      
        if(numAscendantsCookieValue > 0 ) {
        const parsedNumAscendantCookie = numAscendantsCookieValue ? JSON.parse(numAscendantsCookieValue) : 0;
        const numAscendantsCookie = {
            value: parsedNumAscendantCookie,
            label: parsedNumAscendantCookie.toString()
        };
        setNumAscendantsCookie([numAscendantsCookie]);
        ////console.log("numAscendants:", numAscendantsCookie);
        }


      
        const specialFormule = isEcludeBranches && ( selectedOption && selectedOption?.nomOption > "Option 1" || Cookies.get("setOption") !== "Option 1"  ) && selectedFormule?.nomFormule === "Isolé"

   

        const inputPricingData = {
            formule: selectedFormuleCookie ? { nom: 'Formule', valeur: selectedFormuleCookie } : null,
            option: selectedOption && (selectedOption.nomOption || selectedOptionCookie) 
                    ? { nom: 'Option', valeur: selectedOption.nomOption } 
                    : { nom: 'Option', valeur: selectedOptionCookie },
            regime: selectedRegimeCookie ? { nom: 'Regime', valeur: selectedRegimeCookie } : null,
            type: { nom: 'Type', valeur: "Optional" },
            typePersonneCouverte: { 
                nom: 'TypePersonneCouverte', 
                valeur: specialFormule ? champsUpdated : selectedChamps  
            },
            numEnfants: numsEnfants && numsEnfants.length > 0 
                    ? { nom: 'NumEnfants', valeur: numsEnfants } 
                    : { nom: 'NumEnfants', valeur: numEnfantsCookie },
            numAscendants: numsAscendants && numsAscendants.length > 0 
                    ? { nom: 'NumAscendants', valeur: numsAscendants } 
                    : { nom: 'NumAscendants', valeur: numAscendantsCookie }
        };

        console.log("les champs : ", JSON.stringify(selectedChamps, null, 2));
        
        if ((selectedBranch?.includes("Habitat") && selectedFormule?.nomFormule?.includes("Isolé") && (selectedOption?.nomOption === "Option 2" !== Cookies.get("setOption") == "Option 2" )) || 
        (selectedFormule?.nomFormule?.includes("Adulte") && selectedOption?.nomOption === "Option 2")) {
        const salarieExistsInInput = inputPricingData?.typePersonneCouverte?.valeur?.some(champ => champ?.label === 'Salarié');
        const isFormuleFamille = selectedFormule?.nomFormule?.includes("Famille");
        const isSalarieIncludedInCookies = Cookies.get("selectedChamps")?.includes('Salarié');

        if (!salarieExistsInInput && !isFormuleFamille && !isSalarieIncludedInCookies) {
            const salarieData = selectedRegime?.champs?.items.find(champ => champ.nom === 'Salarié');
            if (salarieData) {
                const salarieOption = {
                    value: salarieData.id,
                    label: salarieData.nom,
                    valeur: salarieData.valeur
                };
                const isDuplicate = inputPricingData.typePersonneCouverte.valeur.some(item => item && item.label === salarieOption.label);
                if (!isDuplicate) {
                    inputPricingData.typePersonneCouverte.valeur.push(salarieOption);
                }
            } 
        }
    } 

    if(selectedBranch?.includes("Habitat") && selectedFormule?.nomFormule === "Isolé"  && selectedOption?.nomOption === "Option 2" && Cookies.get("setOption") === "Option 1") {
        const salarieExistsInInput = inputPricingData?.typePersonneCouverte?.valeur?.some(champ => champ?.label === 'Salarié');
        const salarieData = selectedRegime?.champs?.items.find(champ => champ.nom === 'Salarié');
        if (salarieData && !salarieExistsInInput) {
            const salarieOption = {
                value: salarieData.id,
                label: salarieData.nom,
                valeur: salarieData.valeur
            };

            // Ajouter le champ 'Salarié' si ce n'est pas un doublon
            inputPricingData.typePersonneCouverte.valeur.push(salarieOption);
        }
    }
    
        
       
        if ( selectedFormule?.nomFormule.includes("Adulte") && selectedOption?.nomOption === "Option 2" && !excludedeBranches) {
            const salarieExistsInInput = inputPricingData?.typePersonneCouverte?.valeur?.some(champ => champ?.label === 'Salarié');
            if (!salarieExistsInInput) {
                const salarieData = selectedRegime?.champs?.items.find(champ => champ.nom === 'Salarié');
    
                ////console.log(JSON.stringify(salarieData))
                if (salarieData) {
                    const salarieOption = {
                        value: salarieData.id,
                        label:  salarieData.nom,
                        valeur: salarieData.valeur
                    };
                    const isDuplicate = inputPricingData.typePersonneCouverte.valeur.some(item => 
                        item && item.label === salarieOption.label
                    );
                    if (!isDuplicate) {
                        inputPricingData.typePersonneCouverte.valeur.push(salarieOption);
                    }
                } else {
                    console.error("Option salarié non trouvée dans les données");
                }
            }
        }

        if(showOption && selectedBranch?.includes("Architecte") && selectedFormule?.nomFormule === "Isolé" && selectedOption?.nomOption === "Option 2" ){
            if( selectedFormule?.options?.items && Cookies.get("setRegime")) {
                const extensionLabel = selectedChamps?.find(champ => champ?.label?.includes("Extension"))?.label;
                const regimeCookieValue = Cookies.get("setRegime");
            
                const matchingExtensions = selectedFormule.options.items.flatMap(option => 
                  option.regimes.items.filter(regime => regime.nomRegime === regimeCookieValue)
                  .flatMap(regime => regime.champs.items.filter(champ => champ.nom === extensionLabel))
                );
            
                if (matchingExtensions.length > 0) {
                  const newSelectedChamps = matchingExtensions.map(extension => ({
                    value: extension.id,
                    label: extension.nom,
                    valeur: extension.valeur
                  }));
                  if (JSON.stringify(newSelectedChamps) !== JSON.stringify(selectedChamps)) {
                    setSelectedChamps(newSelectedChamps);
                  }
                } else {
                  const salarieData = selectedRegime?.champs?.items.find(champ => champ.nom === 'Salarié');
                  if (salarieData && !selectedChamps.some(champ => champ.label === 'Salarié')) {
                    const newSelectedChamps = [...selectedChamps, {
                      value: salarieData.id,
                      label: salarieData.nom,
                      valeur: salarieData.valeur
                    }];
                    setSelectedChamps(newSelectedChamps); // Mise à jour sans boucle infinie
                  }
                }
              }
        };

        if (selectedBranch?.includes("Architecte") && selectedFormule?.nomFormule === "Isolé" && selectedOption?.nomOption === "Option 2" && !showOption) {
            const newSelectedChamps = computerSwitchCase(selectedChamps, selectedFormule, selectedOption);
            if (newSelectedChamps.length > 0 && JSON.stringify(newSelectedChamps) !== JSON.stringify(selectedChamps)) {
                //console.log("Mise à jour des champs sélectionnés:", newSelectedChamps);
                setSelectedChamps(newSelectedChamps);
            } else if (!attemptedProcessing) {
                //console.log("Pas de changement nécessaire pour selectedChamps");
                setAttemptedProcessing(true); // Marquer comme traité pour éviter les boucles
            }
        }

        if(selectedBranch?.includes("Bureau")) {
            const newSelectedChamps = computerSwitchCase(selectedChamps, selectedFormule, selectedOption, isConjoint);
            //console.log("données traitées:", newSelectedChamps);
        
            // Filtrer les doublons basés sur 'nom' et 'valeur'
            const uniqueChamps = new Map();
            for (const champ of newSelectedChamps) {
                const key = `${champ.nom}-${champ.valeur}`;
                if (!uniqueChamps.has(key)) {
                    uniqueChamps.set(key, champ);
                }
            }
            const filteredChamps = Array.from(uniqueChamps.values());
        
            if (filteredChamps.length !== 0 && JSON.stringify(filteredChamps) !== JSON.stringify(selectedChamps)) {
                setSelectedChamps(filteredChamps);
                //console.log("Nouveaux champs sélectionnés:", filteredChamps);
            } else {
                if (filteredChamps.length === 0) {
                    //console.log("Aucun traitement de données effectué !!");
                    if (!attemptedProcessing) {
                        setSelectedChamps([]); 
                        setAttemptedProcessing(true); 
                    }
                }
            }
        }
        
        

      
        if(Cookies.get("branche") === "PTPC" && Cookies.get("setOption") === "Option 2" && selectedOption?.nomOption === "Option 3" && selectedChamps?.length > 0 && !selectedFormule?.nomFormule.includes("Adule")){
            const salarieData= champsData.filter(champ => champ.nom === "Salarié");
            //console.log("mon champs rechercher", JSON.stringify(salarieData, null, 2))
                if(salarieData.length > 0) {
                   
                const salarieToUdtate = {
                    value: salarieData[0].id,
                    label: salarieData[0].nom,
                    valeur: salarieData[0].valeur
                }
                
                        //console.log("champ salarier mis a jour ", JSON.stringify(salarieToUdtate, null, 2))
                        const isSalarieIncluded = selectedChamps.some(champ => champ.label === 'Salarié');
                        const isDuplicate = inputPricingData.typePersonneCouverte.valeur.some(item =>
                            item && item.label ===  salarieToUdtate.label
                        );
                        if (!isDuplicate && !isSalarieIncluded) {
                            inputPricingData.typePersonneCouverte.valeur.push(salarieToUdtate);
                        }
                    }

        } 

        if (isEcludeBranches && selectedOption?.nomOption === "Option 3" &&  Cookies.get("setOption") === "Option 2" ) {
            const nomOption = selectedOption?.nomOption;
            const nomRegime = Cookies.get("setRegime");
            const salarieDataArray = getSalariéChamps(nomOption, nomRegime);
            let valeurModifiee; // Assurez-vous que cette déclaration est à la portée appropriée
            

            if (salarieDataArray.length > 0) {
                // Déterminez la valeur ajustée en fonction de la branche spécifique
                if (Cookies.get("branche") === "PTPC") {
                    valeurModifiee = parseFloat(salarieDataArray[0].valeur.replace(',', '.')) - 0.20;
                } else {
                    valeurModifiee = parseFloat(salarieDataArray[0].valeur.replace(',', '.')) - 0.27;
                }
            
                const salarieOption = {
                    value: salarieDataArray[0].id,
                    label: salarieDataArray[0].nom,
                    valeur: valeurModifiee.toFixed(2).replace('.', ',') // Utilisez valeurModifiee ici
                };
            
                const isSalarieIncluded = selectedChamps.some(champ => champ.label === 'Salarié');
                const existingSalarieIndex = inputPricingData.typePersonneCouverte.valeur.findIndex(champ => champ.label === 'Salarié');
            
                if (existingSalarieIndex !== -1) {
                    inputPricingData.typePersonneCouverte.valeur[existingSalarieIndex] = salarieOption;
                    //console.log("Champ 'Salarié' existant mis à jour avec les nouvelles données.");
                } else if (!isSalarieIncluded) {
                    inputPricingData.typePersonneCouverte.valeur.push(salarieOption);
                    //console.log("Nouveau champ 'Salarié' ajouté.");
                }
            } else {
                console.error("Aucun champ 'Salarié' trouvé pour l'option et le régime spécifiés.");
            }
            
                } else if (isEcludeBranches){
                    const salarieData = champsData.filter(champ => champ.nom === "Salarié");
                    //console.log("Champs 'Salarié' recherchés", JSON.stringify(salarieData, null, 2));

                    if (salarieData.length > 0 && showOption && selectedChamps.length < 0 && ! selectedFormule?.nomFormule.includes("Adulte")) {
                        // Conversion de la valeur en nombre, ajout de 0.03, puis reconversion en chaîne avec deux décimales
                        const valeurModifiee = (parseFloat(salarieData[0].valeur.replace(',', '.')) + 0.03).toFixed(2).replace('.', ',');
                        const salarieToAdd = {
                            value: salarieData[0].id,
                            label: salarieData[0].nom,
                            valeur: valeurModifiee  // Utilisation de la nouvelle valeur calculée
                        };
                        
                        const isSalarieIncluded = selectedChamps.some(champ => champ.label === 'Salarié');
                        const existingSalarieIndex = inputPricingData.typePersonneCouverte.valeur.findIndex(champ => champ.label === 'Salarié');
                        
                        if (existingSalarieIndex !== -1) {
                            // Mise à jour du champ existant avec la valeur modifiée
                            inputPricingData.typePersonneCouverte.valeur[existingSalarieIndex] = salarieToAdd;
                            //console.log("Champ 'Salarié' existant mis à jour avec les nouvelles données.");
                        } else if (!isSalarieIncluded) {
                            // Ajout du champ 'Salarié' avec la valeur modifiée s'il n'existe pas déjà
                            inputPricingData.typePersonneCouverte.valeur.push(salarieToAdd);
                            //console.log("Nouveau champ 'Salarié' ajouté avec valeur modifiée.");
                        }
                    } else {
                        const nomOption = selectedOption?.nomOption;
                        const nomRegime = Cookies.get("setRegime");
                        const salarieDataArray = getSalariéChamps(nomOption, nomRegime);
                        if (salarieDataArray.length > 0 ) {
                            const salarieToAdd = {
                                value: salarieDataArray[0].id,
                                label: salarieDataArray[0].nom,  // Assumé comme 'nom' utilisé pour le label dans certains cas
                                valeur: salarieDataArray[0].valeur
                            };
                        
                            // Vérifier si un champ 'Salarié' avec la même valeur, nom ou label existe déjà
                            const isSalarieAlreadyIncluded = inputPricingData.typePersonneCouverte.valeur.some(champ =>
                                (champ.label === salarieToAdd.label || champ.nom === salarieToAdd.label) && champ.valeur === salarieToAdd.valeur);
                        
                            if (!isSalarieAlreadyIncluded) {
                                // S'il n'existe pas, ajoutez ou mettez à jour le champ
                                const existingSalarieIndex = inputPricingData.typePersonneCouverte.valeur.findIndex(champ =>
                                    champ.label === 'Salarié' || champ.nom === 'Salarié');
                        
                                if (existingSalarieIndex !== -1) {
                                    inputPricingData.typePersonneCouverte.valeur[existingSalarieIndex] = salarieToAdd;
                                    //console.log("Champ 'Salarié' existant mis à jour avec les nouvelles données.");
                                } else {
                                    inputPricingData.typePersonneCouverte.valeur.push(salarieToAdd);
                                    //console.log("Nouveau champ 'Salarié' ajouté.");
                                }
                            }
                        } else {
                            console.error("Aucun champ 'Salarié' trouvé pour l'option et le régime spécifiés.");
                        }
                        
                    }
                    
                }
                
                
            

               
        
        if (isEcludeBranches && (selectedOption?.nomOption > "Option 1" || Cookies.get("setOption") !== "Option 1") && selectedFormule?.nomFormule === "Isolé") {
            const computedChamps = computerSwitchCase(selectedChamps, selectedFormule, selectedOption, isConjoint);
            //console.log("Champs traités lors de la sélection:", JSON.stringify(computedChamps, null, 2));
            if (computedChamps && computedChamps.length > 0 && JSON.stringify(computedChamps) !== JSON.stringify(champsUpdated)) {
                //console.log("Mise à jour des champs sélectionnés:", JSON.stringify(computedChamps, null, 2));
                setChampsUpdated(computedChamps);  
            } else if (!attemptedProcessing) {
                //console.log("Pas de changement nécessaire pour selectedChamps");
                setAttemptedProcessing(true);
            }

            if (selectedChamps.length === 0 && selectedOption) {
                setComputedPrice(0);
                setPercentage(0);
                setChampsUpdated([...selectedChamps]);
            }


            const hasConjoint = selectedChamps.some(champ => champ.label === "Conjoint");

            if (hasConjoint && selectedOption?.nomOption.includes("Option 2") && Cookies.get("branche") === "PTPC") {
                const nomOption = selectedOption?.nomOption;
                const nomRegime = Cookies.get("setRegime");
                
                // Récupérer les données du champ "Salarié" selon l'option et le régime actuels
                const salarieDataArray = getSalariéChamps(nomOption, nomRegime);
                //console.log("Salary", JSON.stringify(salarieDataArray, null, 2));
                
                if (salarieDataArray.length > 0) {
                    const salarieOption = {
                        value: salarieDataArray[0].id,
                        label: salarieDataArray[0].nom,
                        valeur: salarieDataArray[0].valeur
                    };
                
                    //console.log("Donnée du salarié", JSON.stringify(salarieOption, null, 2));
                
                    const existingSalarieIndex = inputPricingData.typePersonneCouverte.valeur.findIndex(champ => champ.label === 'Salarié');
                    
                    if (existingSalarieIndex !== -1) {
                        // Mettre à jour le champ existant avec les nouvelles valeurs
                        inputPricingData.typePersonneCouverte.valeur[existingSalarieIndex] = salarieOption;
                        //console.log("Champ 'Salarié' mis à jour avec les nouvelles données.");
                    } else {
                        // Supprimer tous les anciens champs "Salarié" avant d'en ajouter un nouveau
                        inputPricingData.typePersonneCouverte.valeur = inputPricingData.typePersonneCouverte.valeur.filter(champ => champ.label !== 'Salarié');
                        // Ajouter le champ "Salarié" puisqu'il n'existe pas
                        inputPricingData.typePersonneCouverte.valeur.push(salarieOption);
                        //console.log("Champ 'Salarié' ajouté.");
                    }
                } else {
                    console.error("Aucun champ 'Salarié' trouvé pour l'option et le régime spécifiés.");
                }
            } 
        }
        
        //console.log("pourcentage" , percentage)
        //console.log("les champs" , JSON.stringify(selectedChamps, null, 2))
            const isSalarieOnly = selectedChamps[0]?.nom === 'Salarié';
            const isFormuleIsoleOption2 = selectedFormule?.nomFormule === "Isolé" && selectedOption?.nomOption === "Option 2" && !isEcludeBranches;
            
            if (isSalarieOnly && isFormuleIsoleOption2) {
                setComputedPrice(0);
                setPercentage(0);
            } else {
                if (selectedBranch && selectedBranch.includes("Bureau")) {
                    const computedResult = computePricingFromPricingType(inputPricingData);
                    const finalPrice = parseFloat(computedResult).toFixed(2);
                    setComputedPrice(finalPrice);
                } else {
                    const computedResult = computePricingFromPricingType(inputPricingData);
                    const computedResultAsString = computedResult?.toString();
                    const finalPrice = parseFloat(computedResultAsString).toFixed(2);  
                    setComputedPrice(finalPrice);
                    
                    const computedResultAsFloat = parseFloat(computedResultAsString);
                    let rawPercentage = computedResultAsFloat / 3864 * 100;
                    let formattedPercentage = parseFloat(rawPercentage).toFixed(2);  
                    
                    setPercentage(formattedPercentage);  
                    
            
                    //console.log("final percentage: " + computedResultAsString);
                }
            }}
            
            const refreshInterval = isEcludeBranches ? 1000 : 5000;
            
    loadData();
    const intervalId = setInterval(loadData, refreshInterval);



    return () => clearInterval(intervalId);
    }, [selectedOption, selectedChamps, numsEnfants, numsAscendants, showOption, isConjoint, selectedRegime, currentChamps, attemptedProcessing]);
   
    



    useEffect(() => {
        const hasConjoint = selectedChamps?.some(champ => champ?.label?.includes('Conjoint'));
       
        Cookies.set('isConjointbox', hasConjoint.toString());
      
        if(isConjoint){
            Cookies.set('isConjointbox', "true");
        }
        const conjointStatus = hasConjoint ? "oui" : "non";
        setConjoint(conjointStatus);
      
        const branchCookie = Cookies.get('branche');
        setSelectedBranch(branchCookie);
        setAttemptedProcessing(false);

        if (Cookies.get('branche')?.includes("Architecte")) {
            const extensionChamp = selectedChamps.find(champ => champ?.label?.includes("Extension"));
            
            //console.log("extension recupez", JSON.stringify(extensionChamp, null, 2))
            if (extensionChamp) {
                const match = extensionChamp.label.match(/Extension\s+(\w+)/);
                if (match && match[1]) {
                    const extensionFirstWord = match[1];
        
                    Cookies.set("updateFormule", extensionFirstWord);
                } else {
                    console.error("Le format du nom de l'extension n'est pas conforme aux attentes.");
                }
            }
        }


        

      
        //console.log("les champs : " + JSON.stringify(selectedChamps, null, 2));
        //console.log("les champs mis a jour : " + JSON.stringify(champsUpdated, null, 2));
        
      }, [selectedBranch, selectedFormule, selectedOption, architecteChampOptions,selectedChamps, isConjoint]); 
     
      useEffect(() => {

        //console.log("les nombres  champs : " + JSON.stringify(selectedChamps.length, null, 2));

        if (isEcludeBranches && selectedFormule?.nomFormule === "Isolé" && showOption) {
            setSelectedChamps([]);
            setChamps([]);
        }
    }, [selectedOption, selectedBranch, selectedFormule, showOption]);
    
      const getSalariéChamps = (selectedOptionName, selectedRegimeName) => {
        let salariéChamps = [];
    
        if (!selectedFormule || !selectedFormule.options) {
            console.error("selectedFormule est null ou ne contient pas 'options'");
            return [];
        }
    
        const option = selectedFormule.options.items.find(opt => opt.nomOption === selectedOptionName);
        if (!option) {
            console.error("Option non trouvée avec le nom:", selectedOptionName);
            return [];
        }
    
        const regime = option.regimes.items.find(reg => reg.nomRegime === selectedRegimeName);
        if (!regime) {
            console.error("Régime non trouvé avec le nom:", selectedRegimeName);
            return [];
        }
    
        regime.champs.items.forEach(champ => {
            if (champ.nom === "Salarié") {
                salariéChamps.push({...champ, optionName: option.nomOption});
            }
        });
    
        //console.log("Champs Salarié récupérés: ", JSON.stringify(salariéChamps, null, 2));
        return salariéChamps;
    };
    
    
      
    

        const handleSuivant = () => {
            const optionName = selectedOption && selectedOption.nomOption ? selectedOption.nomOption : Cookies.get("setOption");
            const brancheCookie = Cookies.get('branche');
         

            if(brancheCookie && brancheCookie.includes("Architecte")){
                const hasExtension = selectedChamps?.some(champ => champ?.nom?.includes("Extension"))
                if (hasExtension) {
                    const extensionChamp = selectedChamps.find(champ => champ.nom.includes("Extension"));
                    if (extensionChamp) {
                        const match = extensionChamp.nom.match(/Extension\s+(\w+)/);
                        if (match && match[1]) {
                            const extensionFirstWord = match[1];
                
                            Cookies.set("updateFormule", extensionFirstWord);
                        } else {
                            console.error("Le format du nom de l'extension n'est pas conforme aux attentes.");
                        }
                    }
                }
            }


        
            Cookies.set("setOptionUpdate", optionName);
            Cookies.set("setdRegimeUpdate", selectedRegime ? selectedRegime.nomRegime : ''); 
            Cookies.set("setChampUpdate", selectedChamp ? selectedChamp.nomChamp : ''); 
            Cookies.set("numEnfantsUpdate", numsEnfants); 
            Cookies.set("numAscendingUpdate", numsAscendants); 
            Cookies.set("resultat", computedPrice); 
            Cookies.set("percentage", percentage); 
            Cookies.set("numConjointsUpdate", Cookies.get("numConjoints") === "1" ? "oui" : "non"); 
        
            
            navigate("/step3");
        };
        

const handlePrecedent = () => {
    const allCookies = Cookies.get(); 
    Object.keys(allCookies).forEach(cookie => {
      if (cookie !== 'email') {
        Cookies.remove(cookie); 
      }
    });
    
    navigate("/status");
};


const getSelectedOptionNumberFromCookie = () => {
    const selectedOptionCookieValue = Cookies.get("setOption");
    return selectedOptionCookieValue ? parseInt(selectedOptionCookieValue.replace('Option', '')) : 1;
}

const getOptionOptions = () => {
    const allOptions = selectedFormule?.options?.items?.map(option => ({
        value: option.id, 
        label: option.nomOption,
        valeur: option.valeur,
        order: parseInt(option.nomOption.replace('Option ', '')) 

        // Vérification de la condition spécifique
    })) || [];

    // Tri des options par ordre croissant selon le numéro de l'option
    allOptions.sort((a, b) => a.order - b.order);

    const filteredOptions = allOptions.filter(option => option.order > getSelectedOptionNumberFromCookie());

    if (filteredOptions.length > 0) {
        return filteredOptions;
    } else if (allOptions.some(option => option.order === getSelectedOptionNumberFromCookie())) {
        return allOptions.filter(option => option.order === getSelectedOptionNumberFromCookie());
    } else {
        return allOptions;
    }
};


const getSelectedOptionFilterData = () => {
    let optionData;
    let regimeData;
    let champsData = [];

    if (isObjectWithProperty(selectedFormule, 'options') && isObjectWithProperty(selectedFormule.options, 'items')) {
        const selectedOptionCookieValue = Cookies.get("setOption");
        optionData = selectedFormule.options.items.find(option => option.nomOption === selectedOptionCookieValue);

        if (isObjectWithProperty(optionData, 'regimes') && isObjectWithProperty(optionData.regimes, 'items')) {
            const selectedRegimeCookieValue = Cookies.get("setRegime");
            regimeData = optionData.regimes.items.find(regime => regime.nomRegime === selectedRegimeCookieValue);

            if (isObjectWithProperty(regimeData, 'champs') && isObjectWithProperty(regimeData.champs, 'items')) {
                champsData = regimeData.champs.items;
            }
        }
    }

  // //console.log( "les donnéesde get formule: " + JSON.stringify(champsData, null, 2));

    return { optionData, regimeData, champsData };
}
function getdataFromFormule() {
    let optionData;
    let regimeData;
    let champsData = [];


    const excludeSalarie = showOption || Cookies.get("setOption") === "Option 1";

    if (isObjectWithProperty(selectedFormule, 'options') && isObjectWithProperty(selectedFormule.options, 'items')) {
        let optionValueToUse;
        if (showOption) {
            optionValueToUse = selectedOption?.nomOption; // Utilise selectedOption.nomOption si showOption est true
        } else {
            const selectedOptionCookieValue = Cookies.get("setOption");
            optionValueToUse = selectedOptionCookieValue === "Option 1" ? selectedOptionCookieValue : selectedOption?.nomOption || selectedOptionCookieValue;
        }
        
        optionData = selectedFormule.options.items.find(option => option.nomOption === optionValueToUse);

        if (isObjectWithProperty(optionData, 'regimes') && isObjectWithProperty(optionData.regimes, 'items')) {
            const selectedRegimeCookieValue = Cookies.get("setRegime");
            regimeData = optionData.regimes.items.find(regime => regime.nomRegime === selectedRegimeCookieValue);

            if (isObjectWithProperty(regimeData, 'champs') && isObjectWithProperty(regimeData.champs, 'items')) {
                const filteredChamps = excludeSalarie 
                    ? regimeData.champs.items.filter(champ => champ.nom !== "Salarié") 
                    : regimeData.champs.items;

                champsData = filteredChamps.map(champ => ({
                    value: champ.id, 
                    label: champ.nom,
                    valeur: champ.valeur
                }));
            }
        }
    }

    ////console.log("les donnéesde get formule : " + JSON.stringify(champsData, null, 2));
    return champsData;
}



const isObjectWithProperty = (obj, prop) => {
    return obj && typeof obj === 'object' && prop in obj;
}

const convertChampsDataToValueLabelPairs = (champsData) => {
   // //console.log("les champs" + JSON.stringify(champsData, null, 2));

    if (!Array.isArray(champsData)) {
        console.error("Invalid champsData data structure. Expected an array.");
        return [];
    }
    return champsData.map(champ => ({
        id: champ.id,
        label: champ.nom,
        value: champ.valeur 
    }));
}

const data = getSelectedOptionFilterData();
const opt = convertChampsDataToValueLabelPairs(data.champsData);


const optionsWithExtension = opt.filter(option => option.label && option.label.includes("Extension"));

    ////console.log("champs extension: ", JSON.stringify(optionsWithExtension, null, 2));
    const getChampOptions = () => {
        return selectedRegime?.champs?.items
          .filter(champ => champ.nom && champ.label !== "Salarié") // Assurez-vous de bien utiliser 'nom' ou 'label' selon la structure de vos données
          .map(champ => ({
            value: champ.id,
            label: champ.nom,
            valeur: champ.valeur
          })) || [];
      };
      
      
    
    

function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const handleOptionChange = (selectedOption) => {
    const foundOption = selectedFormule?.options?.items.find(option => option.id === selectedOption.value);
    setSelectedOption(foundOption);

    //console.log(`Option choisie: ${JSON.stringify(foundOption?.nomOption, null, 2)}`);
    Cookies.set("updateSelectedOption", foundOption);

    const selectedRegimeCookieValue = Cookies.get("setRegime") || "";
    const selectedRegimeFromCookie = foundOption?.regimes?.items.find(regime => regime.nomRegime === selectedRegimeCookieValue);

    if (selectedRegimeFromCookie) {
        setSelectedRegime(selectedRegimeFromCookie);

        // Assurez-vous que chaque champ a une propriété `nom` avant de continuer
        const selectedChampNames = champs
            .filter(champ => champ && champ.nom)
            .map(champ => removeAccents(champ.nom.trim().toLowerCase()));

        //console.log("Noms des champs à rechercher:", selectedChampNames);

        const foundChamps = selectedRegimeFromCookie.champs.items.filter(champ =>
            champ && champ.nom && selectedChampNames.includes(removeAccents(champ.nom.trim().toLowerCase()))
        );

        //console.log(`Champs sélectionnés: ${JSON.stringify(foundChamps, null, 2)}`);
        const updatedSelectedChamps = getAvailableOptions();
        setSelectedChamps(foundChamps);
    } else {
        //console.log(`Aucun régime trouvé correspondant à la valeur du cookie: ${selectedRegimeCookieValue}`);
    }
};




const handleAddChamp = () => {
    const isArchitecteAndIsole = selectedBranch === "Architecte" && selectedFormule.nomFormule.includes("Isolé");
    const maxChamps = isArchitecteAndIsole ? 1 : selectedBranch.includes("Habitat")  ? 3 : 2;

    if (champs.length < maxChamps) {
        setChamps(prevChamps => [...prevChamps, { nom: "", valeur: "" }]);
        if (!showSalarié || isArchitecteAndIsole) { 
            setSelectedChamps(prevSelected => [...prevSelected, { nom: "", valeur: "" }]);
        }
    } else {
        toast.error(`Vous ne pouvez ajouter que ${maxChamps} champ(s) au maximum.`);
    }
};


const handleRemoveChamp = (index) => {
    const newChamps = [...champs];
    const newSelectedChamps = [...selectedChamps];

    if (newSelectedChamps[index] && newSelectedChamps[index].label && newSelectedChamps[index].label.includes("Enfant(s)")) {
        Cookies.remove('numEnfants');
        Cookies.remove('updatenumEnfants');
        //console.log("Cookies numEnfants et updatenumEnfants supprimés.");
        setNumsEnfants([])
    }
    

    newChamps.splice(index, 1);
    newSelectedChamps.splice(index, 1);

    setChamps(newChamps);
    setSelectedChamps(newSelectedChamps);
};


const handleNumEnfantsChange = (index, selectedOption) => {
    setNumsEnfants(prevValues => {
        const updatedNumEnfant = [...prevValues];
        updatedNumEnfant[index] = selectedOption; 
        return updatedNumEnfant;
    });
    Cookies.set('updatenumEnfants', JSON.stringify(selectedOption.value));
   // //console.log("Nombre d'enfants mis à jour:", selectedOption.value);
};

const handleNumAscendantsChange = (index, selectedOption) => {
    setNumsAscendants(prevValues => {
        const updatedNumAscendant = [...prevValues];
        updatedNumAscendant[index] = selectedOption; 
        return updatedNumAscendant;
    });
    Cookies.set('updatenumAscendants', JSON.stringify(selectedOption.value));
   // //console.log("Nombre d'ascendants mis à jour:", selectedOption.value);
};


const handleChampChange = (selectedChamp, index, event) => {
    if (!Array.isArray(selectedRegime?.champs?.items)) {
        console.error("selectedRegime.champs.items n'est pas un tableau ou est indéfini:", selectedRegime.champs.items);
        return;
    }

    if (event) event.stopPropagation();

    if (selectedChamps.some(champ => champ && champ.value === selectedChamp.value)) {
        toast.error("Ce champ a déjà été sélectionné. Veuillez choisir un autre champ.");
        return;
    }

    if (selectedChamp.label.toLowerCase() === 'salarie') {
        toast.error("Vous ne pouvez pas sélectionner 'Salarié' ici.");
        return;
    }

    const foundChamp = selectedRegime.champs.items.find(champ => champ.id === selectedChamp.value);

    const updateChamps = [...selectedChamps];
    const newChamp = selectedChamp.value && selectedChamp.label ?
        {
            value: foundChamp.id,
            label: foundChamp.nom,
            valeur: foundChamp.valeur
        } : {
            id: foundChamp.id,
            nom: foundChamp.nom,
            valeur: foundChamp.valeur,
            régimeId: foundChamp.regimeId,
            régime: foundChamp.regime
        };
    updateChamps[index] = newChamp;
    setSelectedChamps(updateChamps);

    //console.log("ID de champ mis à jour:", foundChamp.id);
    //console.log("Nom du champ mis à jour:", foundChamp.nom);
    //console.log("Valeur du champ:", foundChamp.valeur);
};




const handleChampChanger = (selectedChamp, index, event) => {
    if (event) event.stopPropagation();

    if (!selectedChamp) {
        console.error("Aucun champ n'a été sélectionné.");
        return;
    }

    if (selectedChamps.some(champ => champ && champ.value === selectedChamp.value)) {
        toast.error("Ce champ a déjà été sélectionné. Veuillez choisir un autre champ.");
        return;  
    }

    const newChamp = {
        value: selectedChamp.id,
        label: selectedChamp.label,
        valeur: selectedChamp.value
    };
    const updatedChamps = [...selectedChamps];
    updatedChamps[index] = newChamp;
    setSelectedChamps(updatedChamps);


    //console.log("les valeur : " + JSON.stringify(updatedChamps));
};


const optionOptions = getOptionOptions();
const champOptions = getChampOptions();

const filteredChampOptions = champOptions.filter(option => option.label.toLowerCase() !== 'salarié');


const motsCles = ["extension"];
const optionsFiltrees = filteredChampOptions.length 
    ? filteredChampOptions.filter(option => 
        motsCles.some(motCle => option.label.toLowerCase().includes(motCle))
    ) 
    : [];


const selectedOptionCookie = Cookies.get("setOption");

const handleOption = () => {
    setShowOption(true);    
    setSelectedChamps([]);
    setChamps([]);
    setNumsEnfants([]);
    setNumsAscendants([]);
    Cookies.remove('updatenumEnfants');
    Cookies.remove('updatenumAscendants')
    

};


const getAvailableOptions = () => {
    const selectedValues = selectedChamps
        .filter(champ => champ != null && champ.nom != null)
        .map(champ => champ.nom);
    return optionsWithExtension.filter(option => !selectedValues.includes(option.nom));
};

const handleMouseEnter = () => {
    setShowMessage(true);
};

const handleMouseLeave = () => {
    setShowMessage(false);
};

const handleConjointChange = (event) => {
    const isChecked = event.target.checked;
    setIsConjoint(isChecked);

    if (isChecked) {
      const conjointData = selectedRegime?.champs?.items.find(champ => champ.nom === 'Conjoint');
      if (conjointData && !selectedChamps.some(champ => champ.nom === 'Conjoint')) {
        setSelectedChamps([...selectedChamps, {
          id: conjointData.id,
          nom: conjointData.nom,
          valeur: conjointData.valeur
        }]);
      }
    } else {
      setSelectedChamps(selectedChamps.filter(champ => champ.nom !== 'Conjoint'));
    }
  };


        const handleExtensionChange = (selectedExtension) => {
            if (selectedChamps.some(champ => champ.value === selectedExtension.value)) {
                toast.error("Ce champ a déjà été sélectionné. Veuillez choisir un autre champ.");
                return;
            }
        
            const newSelectedChamps = [{
                value: selectedExtension.value, 
                label: selectedExtension.label, 
                valeur: selectedExtension.valeur 
            }];
        
            setSelectedChamps(newSelectedChamps); 
        };
 


return (
    <div className="formUpdate">
    <div className="formUpdate-container">
        <HeaderPage/>
            <Tableau/>
        <div className="select-box">
        <div className="myNewComponent">
            {!isEcludeBranches &&(
              <>
              {selectedFormule?.nomFormule === "Isolé" && selectedOptionCookie === "Option 1" && !showOption    ?( 
                <div className="formUpdate-body">    
                <div>
                {!showOption && (
             <div className="formUpate-body-option">
             <div className="formUpdate-alert-container" style={{ height: '50px' }}>
                {showMessage && (
                    <Alert variant="filled" severity="info">
                        Pour améliorer les options du salarié et de ses ayants droit.
                    </Alert>
                )}
            </div>

             <div className="formUpdate-option-contaire">
             <button className="formUpdate-option-btn" onClick={handleOption}onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}>Changer d'option</button>
             </div>
             </div>
            )}
                    </div>
                    
                    {champs  && champs.map((_, index) => (
                    <div className="formUpdate-center" key={index}>
                        <div className="formUpdate-select-center">
                            <div className="formUpdate-select-pc">
                        <label className="select-label champ-label">Personne couverte  :</label>
                        {!showOption ? (
                           <Select
                           className="select-champ"
                           options={getAvailableOptions()} 
                           onChange={(selectedChamp) => handleChampChanger(selectedChamp, index)}
                           placeholder="Choisissez un champs..."
                           value={selectedChamps ? selectedChamps[index] : undefined}
                       /> 
                        ):(
                          <Select
                            className="select-champ"
                            options={getChampOptions().filter(option => !option.isDefault) || getAvailableOptions()} // Filtrez l'option "Salarié"
                            onChange={(selectedChamp) => handleChampChange(selectedChamp, index)}
                            placeholder="Choisissez un champe..."
                            value={selectedChamps[index] && !selectedChamps[index].isDefault ? selectedChamps[index] : null}
                        />
                        )}
                        </div>
                           <button className="fromUpdate-btn-ayant-sup" onClick={() => handleRemoveChamp(index)}>
                            Supprimer 
                        </button>
                        
                        </div>
                        {selectedChamps && selectedChamps[index] && selectedChamps[index].label && (
                   <>
                {selectedChamps[index].label.includes("Enfant(s)") && (
                    <div className="formUpdate-select-sup">
                        <label className="select-label">Nombre d'enfants :</label>
                        <Select
                        value={numsEnfants[index]} 
                        onChange={(selectedOption) => handleNumEnfantsChange(index, selectedOption)}
                        options={selectedBranch.includes("Habitat")? [
                                { value: 1, label: '1' },
                                { value: 2, label: '2' },
                                { value: 3, label: '3 ou +' }
                            ]: [{ value: 1, label: '1' },
                                { value: 2, label: '2 ou +' },]}
                        placeholder="Choisissez le nombre d'enfants..."
                        className="select-input"
                    />
                    </div>
                )}
                {selectedChamps[index].label.includes("Ascendant(s)") && (
                    <div className="formUpdate-select-sup">
                        <label className="select-label">Nombre d'ascendants :</label>
                        <Select
                            value={numsAscendants[index]} 
                            onChange={(selectedOption) => handleNumAscendantsChange(index, selectedOption)}
                            options={[
                                {value: 1, label: '1'},
                                {value: 2, label: '2'},
                                {value: 3, label: '3'},
                                {value: 4, label: '4'}
                            ]}
                            placeholder="Choisissez le nombre d'ascendants..."
                            className="select-input"
                        />
                    </div>
                                    )}
                                </>
                            )}
                        </div>
                    ))}
                    <div className="formUpdate-btn-option">
                        <button className="formUpdate-btn-addop1" onClick={handleAddChamp}>Ajouter un ayant-droit</button>
                    </div>
                    </div>

            ):(
                <>
                    {selectedBranch?.includes("Bureau") &&  selectedOptionCookie?.includes("Option 4") ? (
                       <>
                       <ConditionBet
                        selectedFormule={selectedFormule}
                        isConjoint={isConjoint}
                        handleConjointChange={handleConjointChange}
                        />

                </>
                    ):(
                        <>
                        <div>
                <label className="select-label">Option  :</label>
                <Select
                    className="select-option"
                    options={optionOptions}
                    onChange={handleOptionChange}
                    placeholder="Choisissez une option..."
                />

                            <ConditionBet
                            selectedFormule={selectedFormule}
                            isConjoint={isConjoint}
                            handleConjointChange={handleConjointChange}
                            />
                    </div>
                        </>
                    )}    
                       
                </>   
            )}
              </>  
            )}
            </div>
           

                    
             <ConditionHab
               selectedFormule={selectedFormule}
               selectedOption={selectedOption}
               selectedBranch={selectedBranch}
               champs={champs}
               optionsFiltrees={optionsFiltrees}
               handleChampChange={handleChampChange}
               handleRemoveChamp={handleRemoveChamp}
               handleNumEnfantsChange={handleNumEnfantsChange}
               handleNumAscendantsChange={handleNumAscendantsChange}
               handleAddChamp={handleAddChamp}
               numsEnfants={numsEnfants}
               numsAscendants={numsAscendants}
               selectedChamps={selectedChamps}
             />
    
             <ConditionArch 
               selectedFormule={selectedFormule}
               selectedOption={selectedOption}
               selectedBranch={selectedBranch}
               champs={champs}
               optionsFiltrees={optionsFiltrees}
               handleExtensionChange={handleExtensionChange}
             />

             <ConditionCaretPt 
               selectedFormule={selectedFormule}
               selectedOption={selectedOption}
               champs={champs}
               isEcludeBranches={isEcludeBranches}
               selectedChamps={selectedChamps}
               handleAddChamp={handleAddChamp}
               numsEnfants={numsEnfants}
               handleChampChange={handleChampChange}
               handleRemoveChamp={handleRemoveChamp}
               handleNumEnfantsChange={handleNumEnfantsChange}
               optionOptions={optionOptions}
               handleOptionChange={handleOptionChange}
               getChampOptions={getChampOptions}
               getAvailableOptions={getAvailableOptions}
               handleOption={handleOption}
               handleMouseEnter={handleMouseEnter}
               handleMouseLeave={handleMouseLeave}
               showOption={showOption}
               showMessage={showMessage}
               getdataFromFormule={getdataFromFormule}
               setSelectedChamps={setSelectedChamps}
               getSelectedOptionFilterData={getSelectedOptionFilterData}
             />
            


        
                </div>
           
            <div className="formUpdate-text">
            <div className="formUpate-bottom-text">
            
            <h4>
                {selectedBranch?.includes("Bureau") ? (
                    <>
                    Le tarif mensuel appliqué sera de<span style={{ color: "#00314C", marginLeft: "2%" }}>
                        {isNaN(computedPrice) ? '0' : `${computedPrice}`} €
                    </span>
                    </>
                ) : selectedBranch?.includes("Géomètre") ? (
                    <>
                    Le tarif mensuel appliqué sera de <span style={{ color: "#00314C", margin: "0% 2.2% 2.5% 2%", fontSize: "18px" }}>
                        {percentage}
                    </span> % du PMSS soit <span style={{ color: "#00314C", marginLeft: "2%" }}>
                        {isNaN(computedPrice) ? '0' : `${computedPrice}`} €
                    </span> <br/> (PMSS = 3864€ en 2024)
                    </>
                ) : (
                    <>
                    Le tarif mensuel appliqué sera de {isNaN(percentage) ? "0" : (
                        <span style={{ color: "#00314C", margin: "0% 2.2% 2.5% 2%", fontSize: "18px" }}>{percentage}</span>
                    )} % du PMSS soit {isNaN(computedPrice) ? "0" : (
                        <span style={{ color: "#00314C", marginLeft: "2%" }}>{isNaN(computedPrice) ? '0' : `${computedPrice}`} €</span>
                    )} <br/> (PMSS = 3864€ en 2024)
                    </>
                )}
                </h4>

                </div>
                
               
            </div>
            <div className="formUpdate-footer">
                <button className="formUpdate-btn" onClick={handlePrecedent}>Précedent</button>
                <button className="formUpdate-btn" type="submit" onClick={handleSuivant}>Suivant</button>
            </div>
          <MessageSupport/>
        </div>
    </div> 
);
}

export default FormUpdate;