import React, {useContext, useState, useEffect} from "react";
import { Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, TablePagination, TextField, MenuItem, Button, Menu, CircularProgress} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useNavigate} from "react-router-dom";
import {BranchContext} from "../../Context/Branche";
import { Auth } from 'aws-amplify';
import {API} from "aws-amplify";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './css/tableBranch.css'

const styles = {
    paper: {
        bgcolor: "#f3f6f4"
    },
    input: {
        marginTop: "20%",
        marginBottom: "5%",

    }
};

export default function TableBranch() {
    const {branchData, fetchBranchData, deleteBranch} = useContext(BranchContext);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [userGroup, setUserGroup] = useState(null); 
    const [currentBranchId, setCurrentBranchId] = useState(null);
    const [loading, setLoading] = useState(false);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    // const testingPdfGenerationFromAmplifyApi = async () => {
    //     try {
    //         const response = await API.get("apiPdfGenerator", "/pdf/test");
    //         console.log(`response ${JSON.stringify(response)}`);
    //     } catch (error) {
    //         console.error(`error in post apiPdfGenerator/pdf ${error.message}`);
    //         console.log(error);
    //     }
    // };

    // testingPdfGenerationFromAmplifyApi();

    
    useEffect(() => {
        // Cette fonction asynchrone récupère le groupe d'appartenance de l'utilisateur
        const fetchUserGroup = async () => {
            try {
                const session = await Auth.currentSession();
                const groups = session.getIdToken().payload["cognito:groups"] || [];
                if (groups.length) {
                    setUserGroup(groups[0]);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du groupe de l'utilisateur: ", error);
            }
        };
        fetchUserGroup();
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
        setPage(0);
    };

    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setCurrentBranchId(id); // Stocke l'identifiant de la branche en cours
    };
    const handleDelete = async (id) => {
        // Fermer le menu contextuel
        handleClose();
        
        // Vérification des permissions de l'utilisateur
        if (userGroup !== "ADMIN") {
            toast.error("Vous ne pouvez pas supprimer cette branche. Veuillez contacter votre administrateur.");
            return; 
        }
    
        // Confirmation de suppression
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette branche ?')) {
            // Commencer la suppression
            setLoading(true);
            try {
                await deleteBranch(id);
                setLoading(false);
                toast.success("Branche supprimée avec succès!", {
                    onClose: () => {
                        // Recharger les données ou mettre à jour l'état ici si nécessaire
                        fetchBranchData();
                    }
                });
            } catch (error) {
                setLoading(false);
                console.error('Erreur lors de la suppression de la branche : ', error);
                toast.error("Erreur lors de la suppression de la branche.");
            }
        } else {
            console.log("Suppression annulée.");
        }
    };
    
    
    

    useEffect(() => {
        fetchBranchData();
    }, []);

    const displayedBranches = branchData && branchData.length > 0
        ? branchData
            .filter((branch) => {
                const searchRegex = new RegExp(searchText, "i");
                return (
                    searchRegex.test(branch.nomBranche) ||
                    searchRegex.test(branch.formules && branch.formules.items ? Math.max(...branch.formules.items.map(formule => formule.options.length)).toString() : '') ||
                    searchRegex.test(branch.formules ? branch.formules.items.length.toString() : '')
                );
            })
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : [];

    return (
        <div className="table-branch-container">
        {loading ? (
            <div className="loading-container">
                <CircularProgress/>
            </div>
        ) : (
            <>
                <div className="table-branch-search">
                    <TextField
                        style={styles.input}
                        label="Rechercher"
                        variant="outlined"
                        size="small"
                        value={searchText}
                        onChange={handleSearchTextChange}
                    />
                </div>
                <div className="table-branch-content">
                    <TableContainer className="table-container" component={Paper} style={styles.paper}>
                        <Table className="user-table"  aria-label="user table">
                            <TableHead className="table-head">
                                <TableRow>
                                    <TableCell className="cell-head">Branche</TableCell>
                                    <TableCell className="cell-head">Option</TableCell>
                                    <TableCell className="cell-head">Formule</TableCell>
                                    <TableCell className="cell-head">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                                {displayedBranches.map((branch) => (
                                    <TableRow key={branch.id} className="row-content">
                                        <TableCell className="cell-content">{branch.nomBranche}</TableCell>
                                        <TableCell
                                            className="cell-content">{branch.formules && branch.formules.items ? Math.max(...branch.formules.items.map(formule => formule.options.items.length)) : 0}</TableCell>
                                        <TableCell
                                            className="cell-content">{branch.formules ? branch.formules.items.length : 0}</TableCell>
                                        <TableCell className="cell-action">
                                            <Button
                                                className="action-button"
                                                id="basic-button"
                                                aria-controls={open ? "basic-menu" : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? "true" : undefined}
                                                onClick={(event) => handleClick(event, branch.id)}
                                            >
                                                <MoreVertIcon/>
                                            </Button>
                                            <Menu
                                                className="action-menu"
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    "aria-labelledby": "basic-button",
                                                }}
                                            >
                                                <MenuItem className="menu-item" onClick={() => navigate(`/updateBranche/${currentBranchId}`)}>Modifier</MenuItem>
                                                <MenuItem className="menu-item" onClick={() => handleDelete(currentBranchId)}>Supprimer</MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            className="table-pagination"
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={branchData ? branchData.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </div>
                <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            </>
        )}
    </div>
    
    );
}
