import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/formuser.css";
import { updateUser } from './../../graphql/mutations';
import { API, graphqlOperation, Auth } from "aws-amplify";
import { getUser } from './../../graphql/queries';
import AWS from 'aws-sdk';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const initialState = {
    id: "",
    userId: "",
    email: "",
    password: "",
    nom: "",
    prenom: "",
    role: "",
    createdAt: "",
    updatedAt: ""
};

function FormUserUpdate() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialState);
    const [userGroups, setUserGroups] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userId = state?.user?.id;
                if (userId) {
                    const result = await API.graphql(graphqlOperation(getUser, { id: userId }));
                    if (result.data.getUser) {
                        setFormData(result.data.getUser);
                    }
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de l'utilisateur:", error);
                toast.error("Erreur lors de la récupération de l'utilisateur.");
            }
        };

        const checkIfAdmin = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                const groups = currentUser.signInUserSession.accessToken.payload["cognito:groups"];
                if (groups && groups.includes('ADMIN')) {
                    setIsAdmin(true);
                }
            } catch (error) {
                console.error("Erreur lors de la vérification du rôle Admin:", error);
            }
        };

        fetchUserData();
        checkIfAdmin();
    }, [state]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const updateUserGroup = async (email, newGroup) => {
        try {
            await Auth.currentCredentials().then(credentials => {
                AWS.config.update({
                    region: 'us-east-1',
                    credentials: Auth.essentialCredentials(credentials)
                });
            });
    
            const cognito = new AWS.CognitoIdentityServiceProvider();
            
            const listGroupsResponse = await cognito.adminListGroupsForUser({
                UserPoolId: "us-east-1_WKb9RaHPH",
                Username: email
            }).promise();
    
            const currentGroups = listGroupsResponse.Groups.map(group => group.GroupName);
    
            for (const group of currentGroups) {
                if (group !== newGroup) {
                    await cognito.adminRemoveUserFromGroup({
                        UserPoolId: "us-east-1_WKb9RaHPH",
                        Username: email,
                        GroupName: group
                    }).promise();
                }
            }
    
            if (!currentGroups.includes(newGroup)) {
                await cognito.adminAddUserToGroup({
                    UserPoolId: "us-east-1_WKb9RaHPH",
                    Username: email,
                    GroupName: newGroup
                }).promise();
            }
            toast.success("Mise à jour du role réussie!");

        } catch (error) {
            console.error("Erreur lors de la mise à jour du groupe de l'utilisateur:", error);
            toast.error("Erreur lors de la mise à jour du groupe de l'utilisateur.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await API.graphql(graphqlOperation(updateUser, {
                input: {
                    id: formData.id,
                    email: formData.email,
                    nom: formData.nom,
                    prenom: formData.prenom,
                    role: formData.role.toUpperCase(),
                }
            }));

            await updateUserGroup(formData.email, formData.role);
            toast.success("Mise à jour de utilisateur réussie!");

            navigate("/AccountUser");
        } catch (error) {
            console.error(error);
            toast.error("Erreur lors de la mise à jour de l'utilisateur.");
        }
    };

    const handleBack = () => { 
        navigate("/AccountUser")
    }

    return (
        <div className="formuser">
            <div className="formuser-container">
                <form onSubmit={handleSubmit} className="form-body">
                    <div className="formuser-header-btn-container">
                        <button onClick={handleBack} className="formuser-header-btn">
                            <ArrowBackIcon/>
                        </button>
                    </div>
                    <h1 className="formuser-title">Formulaire Utilisateur</h1>
                    <label className="formuser-label">Civilité :</label>
                    <select
                        className="formuser-select"
                        name="civilite"
                        value={formData.civilite}
                        onChange={handleChange}
                    >
                        <option value="">Choisir le genre</option>
                        <option value="mr">Mr</option>
                        <option value="mme">Mme</option>
                    </select>
                    <label className="formuser-label">Prénom :</label>
                    <input
                        className="formuser-input"
                        placeholder="Modifier le prénom"
                        type="text"
                        name="prenom"
                        value={formData.prenom}
                        onChange={handleChange}
                    />
                    <label className="formuser-label">Nom :</label>
                    <input
                        className="formuser-input"
                        placeholder="Modifier le nom"
                        type="text"
                        name="nom"
                        value={formData.nom}
                        onChange={handleChange}
                    />
                    <label className="formuser-label">Role Utilisateur</label>
                    <select
                        className="formuser-select"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                    >
                          <option value="">Choisir le role</option>
                            {isAdmin && <option value="ADMIN">Admin</option>}
                            <option value="GESTIONNAIRE">Gestionnaire</option>
                            <option value="COMMERCIAL">Commercial</option>
                    </select>
                    <button className="formuser-btn" type="submit">
                        Enregistrer
                    </button>
                </form>
            </div>
        </div>
    );
}

export default FormUserUpdate;
