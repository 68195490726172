import { Container, Paper } from "@mui/material";
import React from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import "./css/AccountPage.css";
import TableauUser from "../components/TableUsers";
import { useUserContext } from "../../Context/Users";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

function AccountPage() {
  const navigate = useNavigate();

  const { user, setUser } = useUserContext();

  const handleForm = () => {
    navigate("/formUser");
  };

  return (
    <div className="comptpage">
      <Navbar />
      <div className="comptpage-container">
        <div className="comptpage-table">
          <div className="comptpage-top">
            <button className="comptpage-top-btn " onClick={handleForm}>
              <PersonAddAltIcon/>
            </button>
          </div>
          <Container style={{ width: "100%", height: "100%", backgroundColor: "white"}}>
            <TableauUser />
          </Container>
        </div>
      </div>
    </div>
  );
}

export default AccountPage;
