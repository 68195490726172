import React, {useEffect, useState} from "react";
import "./css/table.css";
import Cookies from "js-cookie";

function Tableau() {
    const [data, setData] = useState([]);
    const [conjoint, setConjoint ] = useState(null)
    const selectedRegimeCookie = Cookies.get("setRegime");
    const selectedOptionCookie = Cookies.get("setOption");
    const selectedFormuleIdCookie = Cookies.get("setFormuleId");
    const selectedChampCookie = Cookies.get("setChamp");
    const selectedFormuleCookie = Cookies.get("setFormule");
    const numEnfantsCookie = Cookies.get("numEnfants");
    const numConjointsCookie = Cookies.get("numConjoints");
    const numAscendantsCookie = Cookies.get("numAscendants");
    const branche = Cookies.get("branche");

    const branchesAffichageEnfants = ["Habitat", "Cartonnage", "PTPC"];
    const afficherColonneEnfants = () => branchesAffichageEnfants.some(nomBranche => branche?.includes(nomBranche));


    useEffect(() => {
        const loadData = () => {
            if(branche?.includes("Habitat") || branche?.includes("Bureaux")){
                const isConjoints = Cookies.get("numConjoints") === "1" ;
                if(selectedFormuleCookie?.includes("Famille") && branche?.includes("Habitat") && Cookies.get("numConjoints") === "0" || Cookies.get("isConjointbox") === "false" && !selectedFormuleCookie?.includes("Enfant") ){
                 setConjoint("-")
                }else {
                 setConjoint(isConjoints ? "oui" : "non");
                }
            }else {
            const isConjoints = Cookies.get("numConjoints") === "1" || Cookies.get("isConjointbox") === "true" || Cookies.get("numConjointsUpdate") === "oui";
            setConjoint(isConjoints ? "oui" : "non");
            }
            


        if (selectedRegimeCookie &&
            selectedOptionCookie &&
            selectedFormuleIdCookie &&
            numEnfantsCookie) {

            // Mapper les données récupérées à la structure attendue
            const mappedData = [
                {
                    regime: selectedRegimeCookie,
                    option_obligatoire: selectedOptionCookie,
                    formule_tarifaire: selectedFormuleCookie,
                    nb_enfant: {value : parseInt(numEnfantsCookie), label: numEnfantsCookie},
                    nb_conjoint: {value : parseInt(numConjointsCookie), label: numConjointsCookie},
                    nb_ascendant: {value : parseInt(numAscendantsCookie), label: numAscendantsCookie},
                    // Ajoutez ici d'autres champs si nécessaire
                },
            ];

            setData(mappedData);
        }
    }
    loadData();        
    console.log('la branchr ', branche)

    const intervalId = setInterval(loadData, 2000);
    return () => clearInterval(intervalId);


    }, []);





    return (
        <div>
            <table className="table">
                <caption className="title-tab">Situation actuelle :</caption>
                <thead className="top-tab">
                <tr>
                    <th className="title-col">Régime</th>
                    <th className="title-col">Option obligatoire</th>
                    <th className="title-col">Formule tarifaire</th>
                   
                    {afficherColonneEnfants() && <th className="title-col">Nombre d'enfants</th>}
                 { branche?.includes("Habitat") && (
                        <>
                    <th className="title-col">Nombre d'ascendants</th>   
                        </>
                    )}
                    { !branche?.includes("Architecte") && (
                    <th className="title-col">Conjoint</th>
                    )}
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="elements">
                            <i className="element">{item.regime}</i>
                        </td>
                        <td className="elements">
                            <i className="element">{item.option_obligatoire}</i>
                        </td>
                        <td className="elements">
                            <i className="element">{item.formule_tarifaire}</i>
                        </td>
                        {afficherColonneEnfants() && (
                            <>
                             <td className="elements">
                            <i className="element">{item.nb_enfant.value || "-"}</i>
                        </td>   
                            </>
                        )}
                   
                         
                        { branche.includes("Habitat") && (
                            <>
                        <td className="elements">
                            <i className="element">{item.nb_ascendant.value || "-"}</i>
                        </td>
                        </>)}
                        { !branche.includes("Architecte") && (
                            <td className="elements">

                            <i className="element">{conjoint}</i>
                         </td>
                        )}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default Tableau;
