import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import "./css/formuser.css";
import {useUserContext} from "../../Context/Users";
import {Auth} from 'aws-amplify';
import {createUser, createBranche} from './../../graphql/mutations';
import {API, graphqlOperation} from "aws-amplify";
import AWS from 'aws-sdk'; // Import AWS SDK
import awsExports from '../../aws-exports';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {GRAPHQL_AUTH_MODE} from "@aws-amplify/api-graphql/lib/types";
import {listUsers} from './../../graphql/queries';
import { toast ,ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

const initialState = {
    civilite: "",
    nom: "",
    prenom: "",
    email: "",
    adresse: "",
    ville: "",
    cpostal: "",
    password: "",
    role: "",
};

function FormUser() {
    const [formData, setFormdata] = useState(initialState);
    const [userGroup, setUserGroup ] = useState(null)

    const navigate = useNavigate();

    const handleback = () => {
        navigate("/AccountUser");
    }

    const handleChange = (e) => {
        setFormdata({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };


 // Vérifiez si l'e-mail est déjà utilisé dans le pool d'utilisateurs
 const checkEmailExists = async (email) => {
    try {
      const usersData = await API.graphql(graphqlOperation(listUsers, { filter: { email: { eq: email } } }), { authMode: GRAPHQL_AUTH_MODE.API_KEY });
      return usersData.data.listUsers.items.length > 0;
    } catch (error) {
      console.error('Error checking for existing email:', error);
      toast.error("Une erreur s'est produite lors de la vérification de l'email.");
      throw error;
    }
  };
  

     const uniquePasswordGeneration = () => {
         const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
         const string_length = 8;
         let randomstring = "";
         for (let i = 0; i < string_length; i++) {
             const rnum = Math.floor(Math.random() * chars.length);
             randomstring += chars.substring(rnum, rnum + 1);
         }
         return randomstring;
     };

// Configuration d'Amplify
AWS.config.update({ region: awsExports.aws_project_region });

// Obtenez les identifiants actuels et configurez AWS SDK
Auth.currentCredentials().then(credentials => {
    AWS.config.credentials = Auth.essentialCredentials(credentials);
});


const handleSubmit = async (e) => {
    e.preventDefault();
      
       const validRoles = ['ADMIN', 'GESTIONNAIRE', 'COMMERCIAL']; 

       if (!validRoles.includes(formData.role.toUpperCase())) {
           toast.error("Rôle spécifié non valide.");
           return;
       }

    const emailExists = await checkEmailExists(formData.email);
    if (emailExists) {
        toast.error("Cette adresse email est déjà utilisée.");
        return;
    }

    if (formData.role.toUpperCase() === 'ADMIN' && userGroup !== 'ADMIN') {
        toast.error("Seul un utilisateur du groupe 'Admin' peut créer un autre utilisateur Admin.");
        return;
    }

    try {
        const randompass = uniquePasswordGeneration();
        const { user } = await Auth.signUp({
            username: formData.email,
            password: randompass,
            attributes: {
                email: formData.email,
            }
        });

        await API.graphql(graphqlOperation(createUser, {
            input: {
                userId: user.username,
                password: randompass,
                email: formData.email,
                nom: formData.nom,
                prenom: formData.prenom,
                role: formData.role.toUpperCase(),
            }
        }));

        if (formData.role) {
               // Configurez AWS Cognito Identity Service Provider
        const cognitoProvider = new AWS.CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: awsExports.aws_project_region,
        });

        // Paramètres pour ajouter l'utilisateur à un groupe
        const params = {
            GroupName: formData.role, // Nom du groupe
            UserPoolId: awsExports.aws_user_pools_id, // ID du pool d'utilisateurs
            Username: user.username,
        };

        // Ajoutez l'utilisateur au groupe
        await cognitoProvider.adminAddUserToGroup(params).promise();
        }

        navigate("/AccountUser");
        toast.success("Compte créé avec succès.");
    } catch (error) {
        console.error('Error signing up:', error);
        toast.error("Erreur lors de l'inscription : " + error.message);
    }
};

useEffect(() => {
    const fetchUserGroup = async () => {
        try {
            const session = await Auth.currentSession();
            const groups = session.getIdToken().payload["cognito:groups"] || [];
            if (groups.length) {
                setUserGroup(groups[0]);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération du groupe de l'utilisateur: ", error);
        }
    };

    fetchUserGroup();
}, []);

    return (
        <div className="formuser">
                  <ToastContainer />

            <div className="formuser-container">
            <form onSubmit={handleSubmit} className="form-body">
                    <div>
                    <button className="formuser-btn-back" onClick={handleback}><KeyboardBackspaceIcon/></button>
                    <h1 className="formuser-title">Formulaire Utilisateur</h1>
                    </div>
                    <label className="formuser-label">Civilité :</label>
                    <select
                        className="formuser-select"
                        name="civilite"
                        value={formData.civilite}
                        onChange={handleChange}
                    >
                        <option value="">--</option>
                        <option value="mr">Mr</option>
                        <option value="mme">Mme</option>
                    </select>
                    <label className="formuser-label">Prénom :</label>
                    <input
                        className="formuser-input"
                        placeholder="saisir le prénom"
                        type="text"
                        name="prenom"
                        value={formData.prenom}
                        onChange={handleChange}
                    />
                    <label className="formuser-label">Nom :</label>
                    <input
                        className="formuser-input"
                        placeholder="saisir le nom"
                        type="text"
                        name="nom"
                        value={formData.nom}
                        onChange={handleChange}
                    />
                    <label className="formuser-label">Email :</label>
                    <input
                        className="formuser-input"
                        placeholder="Saisir le mail"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <label className="formuser-label">Adresse :</label>
                    <input
                        className="formuser-input"
                        placeholder="Saisir  l'adresse"
                        type="text"
                        name="adresse"
                        value={formData.adresse}
                        onChange={handleChange}
                    />
                    <div className="formuser-center">
                        <i className="formuser-center-item">
                            <label className="formuser-label">Ville :</label>
                            <input
                                className="formuser-input"
                                placeholder="Saisir la ville "
                                type="text"
                                name="ville"
                                value={formData.ville}
                                onChange={handleChange}
                            />
                        </i>
                        <i className="formuser-center-item">
                            <label className="formuser-label">Code postal :</label>
                            <input
                                className="formuser-input"
                                placeholder="saisir le code postal "
                                type="text"
                                name="cpostal"
                                value={formData.cpostal}
                                onChange={handleChange}
                            />
                        </i>
                    </div>
                    <label className="formuser-label">Role Utilisateur</label>
                    <select
                        className="formuser-select"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                    >
                        <option value="">Choisir le role</option>
                        {userGroup === 'ADMIN' && <option value="ADMIN">Admin</option>}
                        <option value="GESTIONNAIRE">Gestionnaire</option>
                        <option value="COMMERCIAL">Commercial</option>
                    </select>
                    <button className="formuser-btn" type="submit">
                        Créer un compte utilisateur
                    </button>
                </form>
            </div>
        </div>
    );
}

export default FormUser;