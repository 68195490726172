import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
function ConditionBet({selectedFormule, isConjoint, handleConjointChange}) {

  
  return (
    <div>
      {selectedFormule?.nomFormule?.includes("sans-conjoint") && (
                        <div style={{ display: "flex", alignItems: "center",justifyContent: "center"}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isConjoint}
                                        onChange={handleConjointChange}
                                        color="primary"
                                    />
                                }
                            />
                      <span>{isConjoint ? <p> Le conjoint est bien pris en compte dans la tarification</p> : <p> Cocher la case pour ajouter le conjoint</p>  } </span>

                        </div>
                    )}
    </div>
  )
}

export default ConditionBet
